import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate } from '../components/function';
import Select from 'react-select'

//source url https://gist.github.com/ksafranski/2973986

export default class CurrencySelect extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        select_data:[
          {
            "label": "US Dollar (USD) ($)",
            "value": "USD"
          },
          {
            "label": "Canadian Dollar (CAD) ($)",
            "value": "CAD"
          },
          {
            "label": "Euro (EUR) (€)",
            "value": "EUR"
          },
          {
            "label": "United Arab Emirates Dirham (AED) (د.إ)",
            "value": "AED"
          },
          {
            "label": "Afghan Afghani (AFN) (؋)",
            "value": "AFN"
          },
          {
            "label": "Albanian Lek (ALL) (Lek)",
            "value": "ALL"
          },
          {
            "label": "Armenian Dram (AMD) (դր.)",
            "value": "AMD"
          },
          {
            "label": "Argentine Peso (ARS) ($)",
            "value": "ARS"
          },
          {
            "label": "Australian Dollar (AUD) ($)",
            "value": "AUD"
          },
          {
            "label": "Azerbaijani Manat (AZN) (ман.)",
            "value": "AZN"
          },
          {
            "label": "Bosnia-Herzegovina Convertible Mark (BAM) (KM)",
            "value": "BAM"
          },
          {
            "label": "Bangladeshi Taka (BDT) (৳)",
            "value": "BDT"
          },
          {
            "label": "Bulgarian Lev (BGN) (лв.)",
            "value": "BGN"
          },
          {
            "label": "Bahraini Dinar (BHD) (د.ب.)",
            "value": "BHD"
          },
          {
            "label": "Burundian Franc (BIF) (FBu)",
            "value": "BIF"
          },
          {
            "label": "Brunei Dollar (BND) ($)",
            "value": "BND"
          },
          {
            "label": "Bolivian Boliviano (BOB) (Bs)",
            "value": "BOB"
          },
          {
            "label": "Brazilian Real (BRL) (R$)",
            "value": "BRL"
          },
          {
            "label": "Botswanan Pula (BWP) (P)",
            "value": "BWP"
          },
          {
            "label": "Belarusian Ruble (BYN) (руб.)",
            "value": "BYN"
          },
          {
            "label": "Belize Dollar (BZD) ($)",
            "value": "BZD"
          },
          {
            "label": "Congolese Franc (CDF) (FrCD)",
            "value": "CDF"
          },
          {
            "label": "Swiss Franc (CHF) (CHF)",
            "value": "CHF"
          },
          {
            "label": "Chilean Peso (CLP) ($)",
            "value": "CLP"
          },
          {
            "label": "Chinese Yuan (CNY) (CN¥)",
            "value": "CNY"
          },
          {
            "label": "Colombian Peso (COP) ($)",
            "value": "COP"
          },
          {
            "label": "Costa Rican Colón (CRC) (₡)",
            "value": "CRC"
          },
          {
            "label": "Cape Verdean Escudo (CVE) (CV$)",
            "value": "CVE"
          },
          {
            "label": "Czech Republic Koruna (CZK) (Kč)",
            "value": "CZK"
          },
          {
            "label": "Djiboutian Franc (DJF) (Fdj)",
            "value": "DJF"
          },
          {
            "label": "Danish Krone (DKK) (kr)",
            "value": "DKK"
          },
          {
            "label": "Dominican Peso (DOP) (RD$)",
            "value": "DOP"
          },
          {
            "label": "Algerian Dinar (DZD) (د.ج.)",
            "value": "DZD"
          },
          {
            "label": "Estonian Kroon (EEK) (kr)",
            "value": "EEK"
          },
          {
            "label": "Egyptian Pound (EGP) (ج.م.)",
            "value": "EGP"
          },
          {
            "label": "Eritrean Nakfa (ERN) (Nfk)",
            "value": "ERN"
          },
          {
            "label": "Ethiopian Birr (ETB) (Br)",
            "value": "ETB"
          },
          {
            "label": "British Pound Sterling (GBP) (£)",
            "value": "GBP"
          },
          {
            "label": "Georgian Lari (GEL) (GEL)",
            "value": "GEL"
          },
          {
            "label": "Ghanaian Cedi (GHS) (GH₵)",
            "value": "GHS"
          },
          {
            "label": "Guinean Franc (GNF) (FG)",
            "value": "GNF"
          },
          {
            "label": "Guatemalan Quetzal (GTQ) (Q)",
            "value": "GTQ"
          },
          {
            "label": "Hong Kong Dollar (HKD) ($)",
            "value": "HKD"
          },
          {
            "label": "Honduran Lempira (HNL) (L)",
            "value": "HNL"
          },
          {
            "label": "Croatian Kuna (HRK) (kn)",
            "value": "HRK"
          },
          {
            "label": "Hungarian Forint (HUF) (Ft)",
            "value": "HUF"
          },
          {
            "label": "Indonesian Rupiah (IDR) (Rp)",
            "value": "IDR"
          },
          {
            "label": "Israeli New Sheqel (ILS) (₪)",
            "value": "ILS"
          },
          {
            "label": "Indian Rupee (INR) (টকা)",
            "value": "INR"
          },
          {
            "label": "Iraqi Dinar (IQD) (د.ع.)",
            "value": "IQD"
          },
          {
            "label": "Iranian Rial (IRR) (﷼)",
            "value": "IRR"
          },
          {
            "label": "Icelandic Króna (ISK) (kr)",
            "value": "ISK"
          },
          {
            "label": "Jamaican Dollar (JMD) ($)",
            "value": "JMD"
          },
          {
            "label": "Jordanian Dinar (JOD) (د.أ.)",
            "value": "JOD"
          },
          {
            "label": "Japanese Yen (JPY) (￥)",
            "value": "JPY"
          },
          {
            "label": "Kenyan Shilling (KES) (Ksh)",
            "value": "KES"
          },
          {
            "label": "Cambodian Riel (KHR) (៛)",
            "value": "KHR"
          },
          {
            "label": "Comorian Franc (KMF) (FC)",
            "value": "KMF"
          },
          {
            "label": "South Korean Won (KRW) (₩)",
            "value": "KRW"
          },
          {
            "label": "Kuwaiti Dinar (KWD) (د.ك.)",
            "value": "KWD"
          },
          {
            "label": "Kazakhstani Tenge (KZT) (тңг.)",
            "value": "KZT"
          },
          {
            "label": "Lebanese Pound (LBP) (ل.ل.)",
            "value": "LBP"
          },
          {
            "label": "Sri Lankan Rupee (LKR) (SL Re)",
            "value": "LKR"
          },
          {
            "label": "Lithuanian Litas (LTL) (Lt)",
            "value": "LTL"
          },
          {
            "label": "Latvian Lats (LVL) (Ls)",
            "value": "LVL"
          },
          {
            "label": "Libyan Dinar (LYD) (د.ل.)",
            "value": "LYD"
          },
          {
            "label": "Moroccan Dirham (MAD) (د.م.)",
            "value": "MAD"
          },
          {
            "label": "Moldovan Leu (MDL) (MDL)",
            "value": "MDL"
          },
          {
            "label": "Malagasy Ariary (MGA) (MGA)",
            "value": "MGA"
          },
          {
            "label": "Macedonian Denar (MKD) (MKD)",
            "value": "MKD"
          },
          {
            "label": "Myanma Kyat (MMK) (K)",
            "value": "MMK"
          },
          {
            "label": "Macanese Pataca (MOP) (MOP$)",
            "value": "MOP"
          },
          {
            "label": "Mauritian Rupee (MUR) (MURs)",
            "value": "MUR"
          },
          {
            "label": "Mexican Peso (MXN) ($)",
            "value": "MXN"
          },
          {
            "label": "Malaysian Ringgit (MYR) (RM)",
            "value": "MYR"
          },
          {
            "label": "Mozambican Metical (MZN) (MTn)",
            "value": "MZN"
          },
          {
            "label": "Namibian Dollar (NAD) (N$)",
            "value": "NAD"
          },
          {
            "label": "Nigerian Naira (NGN) (₦)",
            "value": "NGN"
          },
          {
            "label": "Nicaraguan Córdoba (NIO) (C$)",
            "value": "NIO"
          },
          {
            "label": "Norwegian Krone (NOK) (kr)",
            "value": "NOK"
          },
          {
            "label": "Nepalese Rupee (NPR) (नेरू)",
            "value": "NPR"
          },
          {
            "label": "New Zealand Dollar (NZD) ($)",
            "value": "NZD"
          },
          {
            "label": "Omani Rial (OMR) (ر.ع.)",
            "value": "OMR"
          },
          {
            "label": "Panamanian Balboa (PAB) (B/.)",
            "value": "PAB"
          },
          {
            "label": "Peruvian Nuevo Sol (PEN) (S/.)",
            "value": "PEN"
          },
          {
            "label": "Philippine Peso (PHP) (₱)",
            "value": "PHP"
          },
          {
            "label": "Pakistani Rupee (PKR) (₨)",
            "value": "PKR"
          },
          {
            "label": "Polish Zloty (PLN) (zł)",
            "value": "PLN"
          },
          {
            "label": "Paraguayan Guarani (PYG) (₲)",
            "value": "PYG"
          },
          {
            "label": "Qatari Rial (QAR) (ر.ق.)",
            "value": "QAR"
          },
          {
            "label": "Romanian Leu (RON) (RON)",
            "value": "RON"
          },
          {
            "label": "Serbian Dinar (RSD) (дин.)",
            "value": "RSD"
          },
          {
            "label": "Russian Ruble (RUB) (₽.)",
            "value": "RUB"
          },
          {
            "label": "Rwandan Franc (RWF) (FR)",
            "value": "RWF"
          },
          {
            "label": "Saudi Riyal (SAR) (ر.س.)",
            "value": "SAR"
          },
          {
            "label": "Sudanese Pound (SDG) (SDG)",
            "value": "SDG"
          },
          {
            "label": "Swedish Krona (SEK) (kr)",
            "value": "SEK"
          },
          {
            "label": "Singapore Dollar (SGD) ($)",
            "value": "SGD"
          },
          {
            "label": "Somali Shilling (SOS) (Ssh)",
            "value": "SOS"
          },
          {
            "label": "Syrian Pound (SYP) (ل.س.)",
            "value": "SYP"
          },
          {
            "label": "Thai Baht (THB) (฿)",
            "value": "THB"
          },
          {
            "label": "Tunisian Dinar (TND) (د.ت.)",
            "value": "TND"
          },
          {
            "label": "Tongan Paʻanga (TOP) (T$)",
            "value": "TOP"
          },
          {
            "label": "Turkish Lira (TRY) (TL)",
            "value": "TRY"
          },
          {
            "label": "Trinidad and Tobago Dollar (TTD) ($)",
            "value": "TTD"
          },
          {
            "label": "New Taiwan Dollar (TWD) (NT$)",
            "value": "TWD"
          },
          {
            "label": "Tanzanian Shilling (TZS) (TSh)",
            "value": "TZS"
          },
          {
            "label": "Ukrainian Hryvnia (UAH) (₴)",
            "value": "UAH"
          },
          {
            "label": "Ugandan Shilling (UGX) (USh)",
            "value": "UGX"
          },
          {
            "label": "Uruguayan Peso (UYU) ($)",
            "value": "UYU"
          },
          {
            "label": "Uzbekistan Som (UZS) (UZS)",
            "value": "UZS"
          },
          {
            "label": "Venezuelan Bolívar (VEF) (Bs.F.)",
            "value": "VEF"
          },
          {
            "label": "Vietnamese Dong (VND) (₫)",
            "value": "VND"
          },
          {
            "label": "CFA Franc BEAC (XAF) (FCFA)",
            "value": "XAF"
          },
          {
            "label": "CFA Franc BCEAO (XOF) (CFA)",
            "value": "XOF"
          },
          {
            "label": "Yemeni Rial (YER) (ر.ي.)",
            "value": "YER"
          },
          {
            "label": "South African Rand (ZAR) (R)",
            "value": "ZAR"
          },
          {
            "label": "Zambian Kwacha (ZMK) (ZK)",
            "value": "ZMK"
          },
          {
            "label": "Zimbabwean Dollar (ZWL) (ZWL$)",
            "value": "ZWL"
          }
        ],
          currentValue:null
      }
      
    }
  
    componentDidMount(){
      if(this.props.value != null){
        for(let i=0; i<this.state.select_data.length; i++){
            if(this.state.select_data[i].value == this.props.value){
                this.setState({
                    currentValue:this.state.select_data[i]
                })
            }
        }
      }
    }

    Change = (res:any) => {
        if(this.props.onChange != null){
            this.props.onChange(res);
            this.setState({
              currentValue:res
            })
        }
    }
  
    
  
    render() {
        return(
            <Select value={this.state.currentValue} onChange={(res)=>{this.Change(res)}}   options={this.state.select_data} />
        )
    }
}

