import React, { Component } from 'react';
import { IonContent } from '@ionic/react';
import { st } from '../components/css/st';
import { ApiReq, notySend, urlNavigate, getBtnLoader, generateToken, parsGetParams, getSystemConfig } from '../components/function';
import PageLoader from '../components/PageLoader';
import RolesSelect from '../components/RolesSelect';
import TimeZonesSelect from '../components/TimeZonesSelect';
import UsersSelect from '../components/UsersSelect';
import AgencySelect from '../components/AgencySelect';
import CurrencySelect from '../components/CurrencySelect';
import ReactSelect from 'react-select';


let getParams = parsGetParams(window.location.href);

export default class FbaacountsPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        render:'users',
        loader:true,
        table_data:[],
        add_fba_aid:'',
        add_fba_timezone:null,
        add_fba_currency:null,
        add_fba_buyer:null,
        add_fba_agency:null,
        add_fba_loader:false,

        edit_fba_id:null,
        edit_fba_aid:'',
        edit_fba_timezone:null,
        edit_fba_currency:null,
        edit_fba_buyer:false,
        edit_fba_agency:null,
        edit_fba_status:{label:'true', value:true},
        edit_fba_loader:false,
      }
    }
  
    componentDidMount(){
        this.GetTableList();

    }

    GetTableList = () => {
        let dop_api_url = "";
        if(getParams != false && getParams.id != null){
            dop_api_url="?id="+getParams.id;
        }
        ApiReq('/fb_accounts/get_fb_accounts_list/'+dop_api_url).then(res => {
            if(res != false){
              if(res.success == true){
                if(dop_api_url == ""){
                    this.setState({
                        table_data:res.data,
                        loader:false,
                      })
                }
                else{
                    
                    this.setState({
                        edit_fba_id:res.data[0].id,
                        edit_fba_aid:res.data[0].aid,
                        edit_fba_timezone:res.data[0].timezone,
                        edit_fba_currency:res.data[0].currency,
                        edit_fba_buyer:{label:res.data[0].buyer, value:res.data[0].buyer_id},
                        edit_fba_agency:{label:res.data[0].agency, value:res.data[0].agency_id},
                        edit_fba_status:{label:res.data[0].status?'true':'false', value:res.data[0].status?true:false}
                    })
                    setTimeout(() =>{
                        this.setState({
                            render:'edit_user',
                            loader:false,
                        })
                    },100)
                }
                
              }
              else{
                notySend('error', 'error #'+res.error+'\n'+res.info)
              }
            }
        })
    }

    AddFBAccount = () => {
        if(this.state.add_fba_aid != '' && this.state.add_fba_timezone != null && this.state.add_fba_currency != null && this.state.add_fba_buyer != null && this.state.add_fba_agency != null){
            this.setState({
                add_fba_loader:true
            })
            ApiReq('/fb_accounts/add_fb_account/', {aid:this.state.add_fba_aid, timezone:this.state.add_fba_timezone.value, currency:this.state.add_fba_currency.value, buyer:this.state.add_fba_buyer.value, agency:this.state.add_fba_agency.value}).then(res => {
                if(res != false){
                    this.setState({
                        add_fba_loader:false
                    })
                    if(res.success == true){
                        notySend('success', 'success')
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n'+res.info);
                    }
                }
            })
        }
        else{
            notySend('error', 'all params must not be empty!')
        }
    }

    EditFbAccount = () => {
        if(this.state.edit_fba_id != null && this.state.edit_fba_aid != '' && this.state.edit_fba_timezone != null && this.state.edit_fba_currency != null && this.state.edit_fba_buyer != null && this.state.edit_fba_agency != null){
            this.setState({
                edit_fba_loader:true
            })

            let timezone = this.state.edit_fba_timezone;
            if(this.state.edit_fba_timezone.value != undefined){
                timezone = this.state.edit_fba_timezone.value;
            }
            let currency = this.state.edit_fba_currency;
            if(this.state.edit_fba_currency.value != undefined){
                currency = this.state.edit_fba_currency.value;
            }
            //console.log({id:this.state.edit_fba_id, aid:this.state.edit_fba_aid, timezone:timezone, currency:currency, buyer:this.state.edit_fba_buyer.value, agency:this.state.edit_fba_agency.value, status:this.state.edit_fba_status.value})
            ApiReq('/fb_accounts/update_fb_account/', {id:this.state.edit_fba_id, aid:this.state.edit_fba_aid, timezone:timezone, currency:currency, buyer:this.state.edit_fba_buyer.value, agency:this.state.edit_fba_agency.value, status:this.state.edit_fba_status.value}).then(res => {
                if(res != false){
                    this.setState({
                        edit_fba_loader:false
                    })
                    if(res.success == true){
                        notySend('success', 'success')
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n'+res.info);
                    }
                }
            })
        }
        else{
            notySend('error', 'all params must not be empty!')
        }
    } 

    DeleteFbAccount = () => {
        if(this.state.edit_fba_id != null){
            this.setState({
                edit_fba_loader:true
            })
            ApiReq('/fb_accounts/delete_fb_account/', {id:this.state.edit_fba_id}).then(res => {
                if(res != false){
                    this.setState({
                        edit_fba_loader:false,
                    })
                    if(res.success == true){
                        // this.GetTableList();
                        // this.setState({
                        //     render:'users'
                        // })
                        notySend('success', 'success')
                        setTimeout(()=>{
                            urlNavigate('/fb_accounts/')
                        },500)
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n'+res.info);
                    }
                }
            })
        }
        else{
            notySend('error', 'param user_id must not be empty!')
        }
    }

    

    RenderTable = () => {
        if(this.state.loader == true){
          return <div className='text-center'><PageLoader></PageLoader></div>;
        }
        else{
          return (
            <div className='table-responsive' style={(this.state.render == 'users')?{display:'block'}:{display:'none'}}>
              <table className="table">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>aid</th>
                    <th>time zone</th>
                    <th>currency</th>
                    <th>buyer</th>
                    <th>agency</th>
                    <th>status</th>
                    <th>show</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.table_data.map((item:any) => {
                    return (
                      <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.aid}</td>
                            <td>{item.timezone}</td>
                            <td>{item.currency}</td>
                            <td>{item.buyer}</td>
                            <td>{item.agency}</td>
                            <td>{item.status?<span style={{opacity:0.5}} className="badge badge-success">true</span>:<span style={{opacity:0.5}} className="badge badge-danger">false</span>}</td>
                            <td><a href={'/fb_accounts/?id='+item.id}><i onClick={()=>{}} className="material-icons remove_red_eye c_p text-primary">&#xe417;</i></a></td>
                            {/* <td><i onClick={()=>{this.setState({edit_fba_id:item.id, edit_fba_aid:item.aid, edit_fba_timezone:item.timezone, edit_fba_currency:item.currency, edit_fba_buyer:{label:item.buyer, value:item.buyer_id}, edit_fba_agency:{label:item.agency, value:item.agency_id}}); setTimeout(()=>{this.setState({render:'edit_user'})},1000)}} className="material-icons remove_red_eye c_p text-primary">&#xe417;</i></td> */}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )
        }
    }
    
    RenderAddFbAccountForm = () => {
        return(
            <div>
                <div className='form-group'>
                    <label>time zone</label>
                    <TimeZonesSelect onChange={(t:any)=>{this.setState({add_fba_timezone:t})}}></TimeZonesSelect>
                    <label>currency</label>
                    <CurrencySelect onChange={(t:any)=>{this.setState({add_fba_currency:t})}}></CurrencySelect>
                    <label>buyer</label>
                    <UsersSelect  onChange={(t:any)=>{this.setState({add_fba_buyer:t})}}></UsersSelect>
                    <label>agency</label>
                    <AgencySelect onChange={(t:any)=>{this.setState({add_fba_agency:t})}}></AgencySelect>
                    <label>account id</label>
                    <input onInput={(t)=>{this.setState({add_fba_aid:t.currentTarget.value})}} type='number' placeholder='Write id' className='form-control'></input>
                </div>
                <button onClick={()=>{this.AddFBAccount()}} disabled={this.state.add_fba_loader} className='btn btn-primary form-control'>{this.state.add_fba_loader?getBtnLoader():null} add fb account</button>
            </div>
        )
    }

    RenderEditFbAccountForm = () => {
        if(this.state.loader == true){
            return <div className='text-center'><PageLoader></PageLoader></div>;
        }
        else{
            return(
                <div style={(this.state.render == 'edit_user')?{display:'block'}:{display:'none'}}>
                    <div className='form-group'>
                        <label>time zone</label>
                        <TimeZonesSelect value={this.state.edit_fba_timezone} onChange={(t:any)=>{this.setState({edit_fba_timezone:t})}}></TimeZonesSelect>
                        <label>currency</label>
                        <CurrencySelect value={this.state.edit_fba_currency} onChange={(t:any)=>{this.setState({edit_fba_currency:t})}}></CurrencySelect>
                        <label>buyer</label>
                        <UsersSelect value={this.state.edit_fba_buyer}  onChange={(t:any)=>{this.setState({edit_fba_buyer:t})}}></UsersSelect>
                        <label>agency</label>
                        <AgencySelect value={this.state.edit_fba_agency} onChange={(t:any)=>{this.setState({edit_fba_agency:t})}}></AgencySelect>
                        <label>account id</label>
                        <input value={this.state.edit_fba_aid} onInput={(t)=>{this.setState({edit_fba_aid:t.currentTarget.value})}} type='number' placeholder='Write id' className='form-control'></input>
                        <label>status</label>
                        <ReactSelect value={this.state.edit_fba_status} onChange={(t:any)=>{this.setState({edit_fba_status:t})}} options={[{label:'true', value:true}, {label:'false', value:false}]}></ReactSelect>
                    </div>
                    <button onClick={()=>{this.EditFbAccount()}} disabled={this.state.edit_fba_loader} className='btn btn-primary form-control'>{this.state.edit_fba_loader?getBtnLoader():null} edit fb account</button>
                    <hr style={st.hr}></hr>
                    {(getSystemConfig().user_data.role == 'admin')?<button onClick={()=>{this.DeleteFbAccount()}} disabled={this.state.edit_fba_loader} className='btn btn-danger form-control'>{this.state.edit_fba_loader?getBtnLoader():null} delete fb account</button>:null}
                </div>
            )
        }
    }
  
    render() {
        return(
            <div style={st.card}>
                <div className='card-body'>
                    {getParams.id == null?
                        <div className='form-group'>
                            <button onClick={()=>{this.setState({render:'users'});this.GetTableList();}} style={{marginRight:5}} className={(this.state.render == 'users')? 'btn btn-primary':'btn btn-outline-primary'}>fb accounts list</button>
                            {(getSystemConfig().user_data.role != 'buyer')?<button onClick={()=>{this.setState({render:'add_user'})}} style={{marginRight:5}} className={(this.state.render == 'add_user')? 'btn btn-primary':'btn btn-outline-primary'}>add fb account</button>:null}
                        </div>
                    :null}
                    <hr style={st.hr}></hr>
                    {(this.state.render == 'users')?this.RenderTable():null}
                    {(this.state.render == 'add_user')?this.RenderAddFbAccountForm():null}
                    {(this.state.render == 'edit_user')?this.RenderEditFbAccountForm():null}
                </div>
            </div>
        )
    }
}

