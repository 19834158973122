import React, { Component } from 'react';
import { IonContent } from '@ionic/react';
import { st } from '../components/css/st';
import { notySend, getBtnLoader, ApiReq, urlNavigate, setSystemConfig } from '../components/function';


export default class LoginPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        login:'',
        password:'',
        loader:false,
        remember:false,
      }
    }
  
    componentDidMount(){
        

    }


    Login = () => {
        if(this.state.login != '' && this.state.password != ''){
            this.setState({
                loader:true
            })
            ApiReq('/auth/login/', {login:this.state.login, password:this.state.password}).then(res => {
                if(res != false){
                    this.setState({
                        loader:false
                    })
                    if(res.success == true){
                        if(this.state.remember == true){
                            setSystemConfig('remember_auth', {login:this.state.login, password:this.state.password});
                        }
                        setSystemConfig('user_login', true);
                        setSystemConfig('user_data', {login:this.state.login, role:res.role, role_id:res.role_id, user_id:res.user_id, timezone:(res.timezone == '')?null:res.timezone});
                        setSystemConfig('auth_token', res.auth_token);
                        urlNavigate('/main/');
                        
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n'+res.info)
                    }
                }
            })
        }
        else{
            notySend('error', 'login and password must not be empty!');
        }
    }

  
    render() {
        return(
            <div style={{position:'absolute',  width:400, left:'50%', marginLeft:-200, top:'23%', padding:10}}>
                <div style={{background:'#333547', borderRadius:".55rem", padding:20, paddingTop:110, boxShadow: '1px 5px 19px -4px rgba(0,0,0,0.37)', WebkitBoxShadow: '1px 5px 19px -4px rgba(0,0,0,0.37)'}}>
                    <img style={{marginTop:-110}} src={require('../components/img/logo-light.png')} alt="" ></img>
                    <div style={st.card}>
                        <div className='card-body'>
                            {/* <h3 className='text-center text-primary'>Authorization</h3> */}
                            <br></br>
                            <div className='form-group'>
                                <label>login</label>
                                <input onInput={(t)=>{this.setState({login:t.currentTarget.value})}} className='form-control'></input>
                                <label>password</label>
                                <input onInput={(t)=>{this.setState({password:t.currentTarget.value})}} className='form-control' type='password'></input>
                            </div>
                            <div style={{display:'flex', justifyContent:'left'}}>{this.state.remember ? <i onClick={()=>{this.setState({remember:false})}} className="material-icons check_box text-primary c_p">&#xe834;</i>:<i onClick={()=>{this.setState({remember:true})}} className="material-icons check_box_outline_blank text-primary c_p">&#xe835;</i>} Remember me</div>
                            <br></br>
                            <div className='text-center'>
                                <button onClick={()=>{this.Login()}} className='btn btn-outline-primary form-control' style={{borderRadius:20}}>{this.state.loader ? getBtnLoader():null} Login</button>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

