import React, { Component } from 'react';
import { IonContent } from '@ionic/react';
import { st } from '../components/css/st';
import { ApiReq, notySend, urlNavigate, getBtnLoader, generateToken } from '../components/function';
import PageLoader from '../components/PageLoader';
import RolesSelect from '../components/RolesSelect';

export default class AgencyPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        render:'agency_list',
        loader:true,
        table_data:[],
        add_agency_name:'',
        add_agency_percent:0,
        add_agency_loader:false,
      }
    }
  
    componentDidMount(){
       this.GetAgencyList();

    }

    GetAgencyList = () => {
        ApiReq('/agency/get_agency_list/').then(res => {
            //console.log(res)
            if(res != false){
              if(res.success == true){
                let table_data:any = res.data;
                for(let i=0; i<table_data.length; i++){
                    table_data[i].loader_update = false;
                    table_data[i].loader_delete = false;
                }
                this.setState({
                  table_data:table_data,
                  loader:false
                })
                
              }
              else{
                notySend('error', 'error #'+res.error+'\n'+res.info)
              }
            }
        })
    }

    AddAgency = () => {
        if(this.state.add_agency_name != '' && this.state.add_agency_percent != null){
            this.setState({
                add_agency_loader:true
            })
            ApiReq('/agency/add_agency/', {name:this.state.add_agency_name, percent:this.state.add_agency_percent}).then(res => {
                if(res != false){
                    this.setState({
                        add_agency_loader:false
                    })
                    if(res.success == true){
                        notySend('success', 'success')
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n'+res.info);
                    }
                }
            })
        }
        else{
            notySend('error', 'all params must not be empty!')
        }
    }

    EditAgency = (id:number) => {
        let editData:any = {id:id, name:null, percent:null};
        let table_data = this.state.table_data;
        let current_id:number;
        for(let i=0; i<table_data.length; i++){
            if(table_data[i].id == id){
                current_id = i;
                table_data[i].loader_update = true;
                editData.name = table_data[i].name;
                editData.percent = table_data[i].percent;
                break;
            }
        }
        this.setState({
            table_data: table_data
        })
        
        ApiReq('/agency/update_agency/', {id:editData.id, name:editData.name, percent:editData.percent}).then(res => {
            if(res != false){
                table_data[current_id].loader_update = false;
                this.setState({
                    table_data: table_data
                })
                if(res.success == true){
                    notySend('success', 'success')
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
        })
    } 

    DeleteAgency = (id:number) => {
        let table_data = this.state.table_data;
        for(let i=0; i<table_data.length; i++){
            if(table_data[i].id == id){
                table_data[i].loader_delete = true;
                break;
            }
        }
        this.setState({
            table_data: table_data
        })
        ApiReq('/agency/delete_agency/', {id:id}).then(res => {
            if(res != false){
                if(res.success == true){
                    this.GetAgencyList();
                    notySend('success', 'success')
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
        })
    }

    UpdateAgencyList = (id:number, name:string, percent:number) => {
        let table_data = this.state.table_data;
        for(let i=0; i<table_data.length; i++){
            if(table_data[i].id == id){
                table_data[i].name = name;
                table_data[i].percent = percent;
                break;
            }
        }
        this.setState({
            table_data:table_data
        })
    }

    CorrectRole = (role:string) => {
        switch (role) {
            case 'admin':
                return {label:'admin', value:1}
            case 'team_lead':
                return {label:'team_lead', value:2}
            case 'buyer':
                return {label:'buyer', value:3}
        }
    }

    RenderTable = () => {
        if(this.state.loader == true){
          return <div className='text-center'><PageLoader></PageLoader></div>;
        }
        else{
          return (
            <div className='table-responsive'>
              <table className="table">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>name</th>
                    <th>percent (%)</th>
                    <th>edit</th>
                    <th>delete</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.table_data.map((item:any) => {
                    return (
                      <tr key={item.id}>
                          <td>{item.id}</td>
                          <td><input onInput={(t)=>{this.UpdateAgencyList(item.id, t.currentTarget.value, item.percent)}} className='form-control' value={item.name}></input></td>
                          <td><input onInput={(t)=>{this.UpdateAgencyList(item.id, item.name, Number(t.currentTarget.value))}} type='number' className='form-control' value={item.percent}></input></td>
                          <td>{item.loader_update?getBtnLoader():<i onClick={()=>{this.EditAgency(item.id)}} className="material-icons offline_pin c_p text-primary">&#xe90a;</i>}</td>
                          <td>{item.loader_delete?getBtnLoader():<i onClick={()=>{this.DeleteAgency(item.id)}} className="material-icons delete_forever c_p text-danger">&#xe92b;</i>}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )
        }
    }
    
    RenderAddAgencyForm = () => {
        return(
            <div>
                <div className='form-group'>
                    <label>name</label>
                    <input onInput={(t)=>{this.setState({add_agency_name:t.currentTarget.value})}} placeholder='Write name' className='form-control'></input>
                    <label>percent (%)</label>
                    <input onInput={(t)=>{this.setState({add_agency_percent:t.currentTarget.value})}} value={this.state.add_user_salary} className='form-control' type='number'></input>
                </div>
                <button onClick={()=>{this.AddAgency()}} disabled={this.state.add_agency_loader} className='btn btn-primary form-control'>{this.state.add_agency_loader?getBtnLoader():null} add agency</button>
            </div>
        )
    }

    // RenderEditUserForm = () => {
    //     return(
    //         <div>
    //             <div className='form-group'>
    //                 <label>login</label>
    //                 <input onInput={(t)=>{this.setState({edit_user_login:t.currentTarget.value})}} value={this.state.edit_user_login} placeholder='Write user login' className='form-control'></input>
    //                 <label>role</label>
    //                 <RolesSelect onChange={(t:any)=>{this.setState({edit_user_role:t})}} value={this.state.edit_user_role}></RolesSelect>
    //                 <label>salary %</label>
    //                 <input onInput={(t)=>{this.setState({edit_user_salary:t.currentTarget.value})}} value={this.state.edit_user_salary} className='form-control' type='number'></input>
    //                 <div style={{display:'flex', justifyContent:'left'}}>{this.state.edit_user_reset_password ? <i onClick={()=>{this.setState({edit_user_reset_password:false})}} className="material-icons check_box text-primary c_p">&#xe834;</i>:<i onClick={()=>{this.setState({edit_user_reset_password:true})}} className="material-icons check_box_outline_blank text-primary c_p">&#xe835;</i>} update password</div>
    //                 {this.state.edit_user_reset_password ?<><label>new password</label><input placeholder='Write user password' onInput={(t)=>{this.setState({edit_user_password:t.currentTarget.value})}} value={this.state.edit_user_password} className='form-control'></input></>:null}
    //             </div>
    //             <button onClick={()=>{this.EditUser()}} disabled={this.state.edit_user_loader} className='btn btn-primary form-control'>{this.state.edit_user_loader?getBtnLoader():null} edit user</button>
    //             <hr style={st.hr}></hr>
    //             <button onClick={()=>{this.DeleteUser()}} disabled={this.state.edit_user_loader} className='btn btn-danger form-control'>{this.state.edit_user_loader?getBtnLoader():null} delete user</button>
    //         </div>
    //     )
    // }
  
    render() {
        return(
            <div style={st.card}>
                <div className='card-body'>
                    <div className='form-group'>
                        <button onClick={()=>{this.setState({render:'agency_list'});this.GetAgencyList();}} style={{marginRight:5}} className={(this.state.render == 'agency_list')? 'btn btn-primary':'btn btn-outline-primary'}>agency list</button>
                        <button onClick={()=>{this.setState({render:'add_agency'})}} style={{marginRight:5}} className={(this.state.render == 'add_agency')? 'btn btn-primary':'btn btn-outline-primary'}>add agency</button>
                    </div>
                    <hr style={st.hr}></hr>
                    {(this.state.render == 'agency_list')?this.RenderTable():null}
                    {(this.state.render == 'add_agency')?this.RenderAddAgencyForm():null}
                </div>
            </div>
        )
    }
}

