import React, { Component } from 'react';
import { IonContent } from '@ionic/react';

import { getUrlPath, getCheckerDomain, ApiReq, getSystemConfig, notySend } from '../components/function';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../components/css/bootstrap.min.css';
import '../components/css/app.min.css';
import { st } from '../components/css/st';


import Header from '../components/Header';
import MainPage from './MainPage';
import LoginPage from './LoginPage';
import Page404 from './Page404';
import ServiceConnectPage from './ServiceConnectPage';
import UsersPage from './UsersPage';
import ReportsPage from './ReportsPage';
import AgencyPage from './AgencyPage';
import CampaignsPage from './CampaignsPage';
import FbaacountsPage from './FbaccountsPage';
import OffersPage from './OffersPage';


export default class Home extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
          page:<LoginPage></LoginPage>,
          page_full_load:true,
          pay_modal:null,
          setting_modal:null
      }
    }
  
    componentDidMount(){
        
        this.StartRout();

    }


    ContainerPage = (children:any) => {
      return(
        //<div style={{paddingLeft:'5%', paddingRight:'5%', paddingTop:10}}><Header></Header><div style={{marginTop:150}}></div>{children}</div>
        <Header children={children}></Header>
        )
    }
  
    
    StartRout = () => {
        let url:any = getUrlPath();
        let config:any = getSystemConfig();
       
        let routs:any = [
            {
                rout:'login', 
                page_name:'login', 
                page:<LoginPage></LoginPage>,
                roles:[]
            },
            {
              rout:'main', 
              page_name:'Main', 
              page:<>{this.ContainerPage(<MainPage></MainPage>)}</>,
              roles:[]
            },
            {
              rout:'service_connect', 
              page_name:'Service Connect', 
              page:<>{this.ContainerPage(<ServiceConnectPage></ServiceConnectPage>)}</>,
              roles:[]
            },
            {
              rout:'users', 
              page_name:'Users', 
              page:<>{this.ContainerPage(<UsersPage></UsersPage>)}</>,
              roles:['admin']
            },
            {
              rout:'reports', 
              page_name:'Reports', 
              page:<>{this.ContainerPage(<ReportsPage></ReportsPage>)}</>,
              roles:[]
            },
            {
              rout:'agency', 
              page_name:'Agency', 
              page:<>{this.ContainerPage(<AgencyPage></AgencyPage>)}</>,
              roles:['admin']
            },
            // {
            //   rout:'offers', 
            //   page_name:'Offers', 
            //   page:<>{this.ContainerPage(<OffersPage></OffersPage>)}</>,
            //   roles:['admin']
            // },
            {
              rout:'campaigns', 
              page_name:'Campaigns', 
              page:<>{this.ContainerPage(<CampaignsPage></CampaignsPage>)}</>,
              roles:[]
            },
            {
              rout:'fb_accounts', 
              page_name:'FB accounts', 
              page:<>{this.ContainerPage(<FbaacountsPage></FbaacountsPage>)}</>,
              roles:['admin', 'team_lead', 'buyer']
            },
        ];

        
       
        
        if(url.length == 0){
            if(config.user_login == false){
                window.location.replace('/login/');
            }
            else{
                window.location.replace('/main/');
            }
        }
        else{
          if(config.user_login == true){
            let isset_page = false;
            for(let i=0; i < routs.length; i++){
                if(url[0] == routs[i].rout){
                    if(config.user_data.role != null){
                        if(routs[i].roles.length !== 0){
                            if(routs[i].roles.indexOf(config.user_data.role) != -1){
                                isset_page = true;
                                localStorage.setItem('page_name', routs[i].page_name);
                                this.setState({
                                    page:routs[i].page
                                })
                                break;
                            }
                        }
                        else{
                            isset_page = true;
                            localStorage.setItem('page_name', routs[i].page_name);
                            this.setState({
                                page:routs[i].page
                            })
                            break;
                        }
                    }
                    else{
                        isset_page = true;
                        localStorage.setItem('page_name', routs[i].page_name);
                        this.setState({
                            page:routs[i].page
                        })
                        break;
                    }
                }
            }
            
            if(isset_page == false){
                this.setState({
                    page:<Page404></Page404>
                })
            }
          }
          else{
            if(url[0] != 'login'){
              window.location.replace('/login/');
            }
            
          }  
        }
        
    }


    

    RoutRender = () => {
        return this.state.page
    }

  
    render() {
        return(
          <div style={{...{overflowY:'scroll', width:'100%', height:'100%'}, ...st.bgBody}}> 
              <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
              <div id='mainwrapper' className="wrapper" >
                  {this.RoutRender()}
                  <ToastContainer newestOnTop={true} />
              </div>
          </div>
        )
        // return (
        //     <>
        //       <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
        //       <div >
        //           {this.RoutRender()}
        //           <ToastContainer newestOnTop={true} />
        //       </div>
        //     </>
        // )
    }
}

