import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { UserAgentProvider, useUserAgent } from 'react-ua';
import moment from 'moment-timezone';


export function getUrlPath() {
    let url:any = window.location.pathname;
    url = url.split('/');
    url = url.filter(function (el:any) {
        return (el != null && el != "" || el === 0);
    });

    return url;
}

export function getCurrentApiUrl() {
    return window.location.host+'/api/leads/add_lead/';
}

export function urlNavigate(url:any) {
    window.location.replace(url);
}

export function getApiDomain() {
    if(window.location.host.indexOf('localhost') == -1){
        return 'https://'+window.location.host+'/api';
    }
    else{
        return 'http://analyticscrm:8888/api';
    }
    
    // return 'https://demo.cryptotrafpar.tech/api';
}

export function getCheckerDomain() {
    // return 'http://checkerpixelcrm:8888/api';
    return 'https://system.pixelcrm.dev/api';
}

export function generateToken(length=20) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}


export function notySend(type:any=null, text:any) {
    let timeout = 5000;
    if(type == 'success'){
        timeout = 100;
    }
    let toastOption:any = {
        position: "top-right",
        autoClose: timeout,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "dark",
        newestOnTop:true,
        
    };

    switch (type) {
        case 'success':
            toast.success(text, toastOption);
            break;
        case 'error':
            toast.error(text, toastOption);
            break;
        case 'warning':
            toast.warning(text, toastOption);
            break;
        default:
            toast.error(text, toastOption);
    }
}


export function parsGetParams(url:any) {
    if(url.indexOf('?') != -1){
        let params = url.split('?');
        if(params[1].indexOf('&') != -1){
            let arr_params = params[1].split('&');
            let retern_arr_params:any = {};
            for(let i=0; i<arr_params.length; i++){
                let temp = arr_params.split('=');
                retern_arr_params[temp[0]] = temp[1];
            }
            return retern_arr_params;
        }
        else{

            let temp = params[1].split('=');
            let retern_arr_params:any = {};
            retern_arr_params[temp[0]] = temp[1];
            return retern_arr_params;
        }
        
        return params;
    }
    else{
        return false;
    }
}


export function ApiReq(url:any=null, body:any=null) {
    let check_dop_req = true;
    let apidomain = getApiDomain();
    let headers = {'Content-Type': 'application/json', 'Auth-Token': getApiToken()};
    let reqParam:any = {
        method: 'GET',
        headers: headers,
    };
    if(body != null){
        reqParam.method = 'POST';
        reqParam.body = JSON.stringify(body)
    }

    // console.warn({headers:headers, body:body, url:url})
    return fetch(apidomain+url, reqParam).then((response) => {
        // console.log(url)
        if(response.status == 200){
            return response.json().then(res => {
                if(res.info != undefined){
                    if(res.info.indexOf('auth undefined') !== -1){
                        notySend('warning', 'The server returned an authorization error, first try reloading the page, perhaps the server simply did not have time to process your request, if the situation has not changed, contact the manager!');
                        // urlNavigate('/login/');
                        // console.log('alarm razlogin '+ res)
                    }
                }
                return res; 
            });
        }
        else{
            return DopApiReq(apidomain+url, reqParam);
        }
    }).catch(error => {
        notySend('error', error+'\nurl:'+apidomain+url);
        return false;
    });;
}

export function DopApiReq(url:string, req_param:any){
    return fetch(url, req_param).then((response) => {
        if(response.status == 200){
            return response.json().then(res => {
                return res; 
            });
        }
        else{
            return false;
        }
    }).catch(error => {
        //notySend('error', error+'\nurl:'+url);
        return false;
    });;
}

export function getApiToken() {
    let config = getSystemConfig();
    return config.auth_token;
}

export function isJson(str:string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function getSystemConfig(){
    let startConfig = {
        theme:'white',
        user_login:false,
        user_data:{login:null, role:null, role_id:null, user_id:null, timezone:null},
        auth_token:null,
        remember_auth:{login:'', password:''}
    }

    let config:any = localStorage.getItem('crmSystemConfig');
    if(config != null){
        return JSON.parse(config);
    }
    else{
        return startConfig;
    }
}

export function setSystemConfig(param:any, value:any){
    let config:any = getSystemConfig();
    config[param] = value;
    localStorage.setItem('crmSystemConfig', JSON.stringify(config))
}

export function getBtnLoader(){
    return <div className="spinner-border spinner-border-sm text-warning"></div>
}

export function getDateTimeZoneFromUnix(unixtime:number, timezone:string){
    return moment.unix(unixtime).tz(timezone).format('YYYY-MM-DD HH:mm');
}
