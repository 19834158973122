import React, { Component } from 'react';
import { IonContent } from '@ionic/react';
import { st } from '../components/css/st';
import PageLoader from '../components/PageLoader';
import { ApiReq, notySend, urlNavigate, getBtnLoader, generateToken, parsGetParams } from '../components/function';
import CustomModal from '../components/CustomModal';
//ascending or descending


export default class ReportTable extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            thead:[],
            tbody:[],
            start_tbody:[],
            total:[],
            show_on_page:20,
            current_page:1,
            leads_table_modal:false,
            leads_table_modal_data:[],
        }
    }
  
    componentDidMount(){
        if(this.props.thead != null && this.props.tbody != null){
            let thead:any = [];
            for(let i=0; i<this.props.thead.length; i++){
                thead.push({name:this.props.thead[i], sort:false, sort_direction:'descending', total:0});
            }
            let start_tbody:any = [];
            for(let i=0; i<this.props.tbody.length; i++){
                start_tbody.push(this.props.tbody[i])
            }
            this.setState({
                thead:thead,
                tbody:this.props.tbody,
                start_tbody:start_tbody
            })
            setTimeout(()=>{
                this.CountingTotal();
            },500)
        }
        else{
            notySend('error', 'params thead and tbody must not by empty!')
        }
    }
    
    SortTable = (index:number) => {
        let thead = this.state.thead;
        //set all items to sort false
        for(let i=0; i<thead.length; i++){
            if(i != index){
                thead[i].sort = false;
                thead[i].sort_direction = 'descending';
            }
        }

        //set active sorting item
        if(thead[index].sort == true){
            if(thead[index].sort_direction == 'descending'){
                thead[index].sort_direction = 'ascending';
            }
            else{
                thead[index].sort = false;
                thead[index].sort_direction = 'descending';
            }
        }
        else{
            thead[index].sort = true;
        }

        this.setState({
            thead:thead
        })

        //for sorting 
        let tbody:any = this.state.tbody;
        if(thead[index].sort != false){
            if(thead[index].sort_direction == 'ascending' ){
                tbody = tbody.sort((a:any, b:any) => a[thead[index].name.toLowerCase()] > b[thead[index].name.toLowerCase()] ? 1 : -1);
            }
            else{
                tbody = tbody.sort((a:any, b:any) => a[thead[index].name.toLowerCase()] < b[thead[index].name.toLowerCase()] ? 1 : -1);
            }
        }
        else{
            tbody = this.state.start_tbody;
        }
        this.setState({
            tbody:tbody
        })
    }

    SetShowOnPage = (num:any) => {
        if(num != '' && num != null){
            if(Number.isInteger(Number(num))){
                this.setState({show_on_page:Number(num)})
            }
        }
    }

    RenderPagination = () => {
        let countPages = Math.ceil(this.props.tbody.length/this.state.show_on_page);
        let pagination = [];
        for(let i=1; i<=countPages; i++){
            pagination.push({num:i, active:(this.state.current_page == i)?true:false});
        }

        return(
            <ul className="pagination pagination-sm justify-content-end">
                <li onClick={()=>{(this.state.current_page>1)?this.setState({current_page:this.state.current_page - 1}):null}} className="page-item c_p"><span className="page-link" >Previous</span></li>
                {pagination.map((item:any) => {
                    return (
                        <li key={item.num} onClick={()=>{this.setState({current_page:item.num})}} className={item.active?'page-item c_p active':'page-item c_p'}><span className="page-link">{item.num}</span></li>
                    )
                })}
                <li onClick={()=>{(this.state.current_page<countPages)?this.setState({current_page:this.state.current_page + 1}):null}} className="page-item c_p"><span className="page-link">Next</span></li>
            </ul>
        )
    }

    CountingTotal = () => {
        let thead = this.state.thead;
        let tbody = this.state.tbody;
        for(let i=1; i<thead.length; i++){
            if(this.GetCharForField(thead[i].name) == null){
                for(let j=0; j<tbody.length; j++){
                    thead[i].total = thead[i].total + tbody[j][thead[i].name.toLowerCase()]
                }
            }
            else{
                if(thead[i].name == 'ROI'){
                    thead[i].total = this.GetTotalFromTable('Profit')/this.GetTotalFromTable('Cost')*100;
                }
                if(thead[i].name == 'CPC'){
                    thead[i].total = this.GetTotalFromTable('Cost')/this.GetTotalFromTable('Clicks');
                }
                if(thead[i].name == 'EPC'){
                    thead[i].total = this.GetTotalFromTable('Revenue')/this.GetTotalFromTable('Clicks');
                }
                if(thead[i].name == 'CR'){
                    thead[i].total = this.GetTotalFromTable('Lead')/this.GetTotalFromTable('Clicks')*100;
                }
                if(thead[i].name == 'CPL'){
                    thead[i].total = this.GetTotalFromTable('Cost')/this.GetTotalFromTable('Lead');
                }
                // let temp = 0;
                // for(let j=0; j<tbody.length; j++){
                //     thead[i].total = thead[i].total + tbody[j][thead[i].name.toLowerCase()]
                //     temp ++;
                // }

                // thead[i].total = (thead[i].total/(temp)).toFixed(2);
            }
            
        }
        this.setState({
            thead:thead
        })
    }

    GetTotalFromTable = (name:string) => {
        let thead = this.state.thead;
        for(let j=0; j<thead.length; j++){
            if(thead[j].name == name){
                return thead[j].total;
            }
        }
    }

    GetCharForField = (field_name:string) => {
        switch (field_name.toLocaleLowerCase()) {
            case 'roi':
                return '%';
            case 'cr':
                return '%';
            case 'cpc':
                return '';
            case 'epc':
                return '';
            case 'cpl':
                return '';
            default:
                return null;
        }
    }

    CountingSpecialCR = () => {
        let thead = this.state.thead;
        let tbody = this.state.tbody;
        let total = 0;
        for(let i=0; i<tbody.length; i++){
            if(tbody[i].cr != 0){
                total = total + ((1/tbody[i].cr)*100);
            }
        }
        return '% (1:'+(total/tbody.length).toFixed(2)+')';
    }
  
    render() {
        return(
            <div style={st.card}>
                <div className='card-body'>
                    <div className='table-responsive'>
                    <table className='table table-bordered table-hover'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    {this.state.thead.map((item:any) => {
                                        if(this.state.thead.indexOf(item) != 0){
                                            return (
                                                <th key={this.state.thead.indexOf(item)}>{item.name} {item.sort?(item.sort_direction == 'descending'?<i onClick={()=>{this.SortTable(this.state.thead.indexOf(item))}} style={{float:'right'}} className="material-icons c_p text-primary arrow_circle_down">&#xf181;</i>:<i onClick={()=>{this.SortTable(this.state.thead.indexOf(item))}} style={{float:'right'}} className="material-icons c_p text-primary arrow_circle_up">&#xf182;</i>):<i onClick={()=>{this.SortTable(this.state.thead.indexOf(item))}} style={{float:'right', opacity:0.5}} className="material-icons c_p text-primary mode_standby">&#xf037;</i>}</th>
                                            )
                                        }
                                        else{
                                            return (
                                                <th key={this.state.thead.indexOf(item)}>{item.name}</th>
                                            )
                                        }
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tbody.map((item:any) => {
                                    let current_page = this.state.current_page - 1;
                                    let start = this.state.show_on_page*current_page;
                                    if(this.state.tbody.indexOf(item)+1 > start && this.state.tbody.indexOf(item)+1 <= start+this.state.show_on_page){
                                        
                                        return (
                                            <tr key={this.state.tbody.indexOf(item)}>
                                                <td>{this.state.tbody.indexOf(item)+1} <i onClick={()=>{this.setState({leads_table_modal:true, leads_table_modal_data:item.leads_list})}} style={{fontSize:12, padding:0, margin:0}} className="material-icons text-primary c_p panorama_fish_eye">&#xe40c;</i></td>
                                                {this.state.thead.map((item2:any) => {
                                                    return (
                                                        <td key={this.state.thead.indexOf(item2)}>{item[item2.name.toLowerCase()]} {this.GetCharForField(item2.name)} {(item2.name.toLowerCase() == 'cr')?'(1:'+((1/item[item2.name.toLowerCase()])*100).toFixed(2)+')':null}</td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    }
                                    else{
                                        return null;
                                    }
                                })}
                            </tbody>
                            <thead>
                                <tr>
                                    <th>{this.state.tbody.length}</th>
                                    <th>Total</th>
                                    {this.state.thead.map((item:any) => {
                                        if(this.state.thead.indexOf(item) > 0){
                                            if(item.name.toLowerCase() == 'cr'){
                                                return (
                                                    <th key={this.state.thead.indexOf(item)}>{Number(item.total).toFixed(2)} {this.CountingSpecialCR()}</th>
                                                )
                                            }
                                            else{
                                                return (
                                                    <th key={this.state.thead.indexOf(item)}>{Number(item.total).toFixed(2)} {this.GetCharForField(item.name)}</th>
                                                )
                                            }
                                        }
                                    })}
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <ul className="pagination pagination-sm">
                                <li className="page-item"><span className="page-link">show <input onInput={(t)=>{this.SetShowOnPage(t.currentTarget.value)}} value={this.state.show_on_page} style={{width:30, padding:0, textAlign:'center', borderRight:'none', borderLeft:'none', borderTop:'none', borderColor:'#6f67ec'}}></input> rows on page</span></li>
                            </ul>
                        </div>
                        <div className='col'>
                            {this.RenderPagination()}
                        </div>    
                    </div>  
                </div>
                <CustomModal header={'Leds list'} size={'big'} visible={this.state.leads_table_modal} callback={()=>{this.setState({leads_table_modal:false})}}>
                    <div style={{overflowY:'scroll', maxHeight:500}}>
                    <div className='table-responsive' >
                        <table className='table table-bordered table-hover'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>sale datetime (keitaro)</th>
                                    <th>os</th>
                                    <th>campaign_id</th>
                                    <th>offer</th>
                                    <th>affiliate_network</th>
                                    <th>country</th>
                                    <th>revenue</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.leads_table_modal_data.map((item:any) => {
                                    return (
                                        <tr key={this.state.leads_table_modal_data.indexOf(item)}>
                                            <td>{this.state.leads_table_modal_data.indexOf(item) + 1}</td>
                                            <td>{item.sale_datetime}</td>
                                            <td>{item.os}</td>
                                            <td>{item.sub_id_2}</td>
                                            <td>{item.offer_group}</td>
                                            <td>{item.affiliate_network}</td>
                                            <td>{item.country}</td>
                                            <td>{item.revenue}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    </div>
                </CustomModal>
            </div>
        )
    }
}

