import React, { Component } from 'react';
import { IonContent } from '@ionic/react';
import { st } from './css/st';
import { getUrlPath, getSystemConfig, ApiReq, notySend, setSystemConfig, getApiDomain } from '../components/function';

let url = getUrlPath();
let systemConfig = getSystemConfig();

export default class Header extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            navigation_list:[
                {id:0, icon:<i style={{fontSize:20, paddingRight:10}} className="material-icons home text-white">&#xe88a;</i>, name:'Main', href:'/main/', active:false, perm:[]},
                {id:1, icon:<i style={{fontSize:20, paddingRight:10}} className="material-icons library_books text-white">&#xe02f;</i>, name:'FB accounts', href:'/fb_accounts/', active:false, perm:['admin', 'team_lead', 'buyer']},
                {id:2, icon:<i style={{fontSize:20, paddingRight:10}} className="material-icons account_balance_wallet text-white">&#xe850;</i>, name:'Agency', href:'/agency/', active:false, perm:['admin']},
                
                {id:3, icon:<i style={{fontSize:20, paddingRight:10}} className="material-icons device_hub text-white">&#xe335;</i>, name:'Campaigns', href:'/campaigns/', active:false, perm:[]},
                {id:4, icon:<i style={{fontSize:20, paddingRight:10}} className="material-icons bar_chart text-white">&#xe26b;</i>, name:'Reports', href:'/reports/', active:false, perm:[]},
                {id:5, icon:<i style={{fontSize:20, paddingRight:10}} className="material-icons hub text-white">&#xe9f4;</i>, name:'Service connect', href:'/service_connect/', active:false, perm:['admin']},
                {id:6, icon:<i style={{fontSize:20, paddingRight:10}} className="material-icons people text-white">&#xe7fb;</i>, name:'Users', href:'/users/', active:false, perm:['admin']},
                
            
            ],
            navBlock:false,
            dateTimeTimeZone:{date:null, time:null, timezone:null}
        }
    }
  
    componentDidMount(){
        this.SetActiveNavItem();

        
        this.setState({
            dateTimeTimeZone:this.GetDateTimeInTimeZone(systemConfig.user_data.timezone)
        })
        setInterval(()=>{
            this.setState({
                dateTimeTimeZone:this.GetDateTimeInTimeZone(systemConfig.user_data.timezone)
            })
        }, 1000)
    }

    SetActiveNavItem = (id:any=null) => {
        let navigation_list = this.state.navigation_list;
        if(id == null){
            for(let i=0; i<navigation_list.length; i++){
                if(navigation_list[i].href.indexOf(url[0]) != -1){
                    navigation_list[i].active = true;
                    break;
                }
            }
            
        }
        else{
            navigation_list[id].active = !navigation_list[id].active;
        }

        this.setState({
            navigation_list:navigation_list
        })
    }

    LogOut = () => {
        ApiReq('/auth/logout/').then(res => {
          if(res != false){
            setSystemConfig('user_login', false);
            setSystemConfig('user_data', {login:null, role:null, role_id:null, user_id:null, timezone:null});
            setSystemConfig('auth_token', null);
            window.location.replace('/login/')
            if(res.success == true){
                
            }
            else{
                //window.location.replace('/login/')
                notySend('error', 'error #'+res.error+'\n'+res.info)
            }
          }
        })
    }
    
    GetDateTimeInTimeZone = (timezone:any) => {
        if(timezone != null || timezone != ''){
            let options:any = {
                timeZone: timezone,
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            },
            formatter = new Intl.DateTimeFormat([], options);
            let pd = String(formatter.format(new Date())).split(',')
            pd[1].replace(' ', '');
            let time = pd[1].split(':');
            pd[1] = time[0]+':'+time[1];
            return {date:pd[0], time:pd[1], timezone:timezone};
        }
        else{
            return {date:null, time:null, timezone:null};
        }
        
    }
  
    render() {
        // return(
        //     <>
        //         <div style={{ width:'90%', position:'fixed'}}>
        //             <div style={st.card}>
        //                 <div className='card-body'>
        //                     <div className='row'>
        //                         <div className='col'>
        //                             {/* <img style={{width:30}} src={require('../components/img/logo.png')}></img> */}
        //                             <strong className='text-primary'> Analytics CRM</strong>
        //                         </div>
        //                         <div className='col' style={{textAlign:'right'}}>
        //                             {/* <span style={{marginRight:10}}><i className="material-icons wb_sunny c_p text-secondary">&#xe430;</i></span> */}
        //                             <span onClick={()=>{this.LogOut()}}><i className="material-icons power_settings_new c_p text-danger">&#xe8ac;</i></span>
        //                         </div>
        //                     </div>
        //                     <hr style={st.hr}></hr>
        //                     <div style={{ display:'flex', overflowX:'scroll', overflowY:'hidden'}}>
        //                         <a href='/main/' style={{borderRadius:20, marginRight:5, maxHeight:38}} className={(url[0]=='main'?'btn btn-outline-primary active':'btn btn-outline-primary')}>Main</a>
        //                         {(systemConfig.user_data.role == 'admin')?<a href='/users/' style={{borderRadius:20, marginRight:5, maxHeight:38}} className={(url[0]=='users'?'btn btn-outline-primary active':'btn btn-outline-primary')}>Users</a>:null}
        //                         {(systemConfig.user_data.role == 'admin')?<a href='/service_connect/' style={{borderRadius:20, marginRight:5, maxHeight:38}} className={(url[0]=='service_connect'?'btn btn-outline-primary active':'btn btn-outline-primary')}>Service connect</a>:null}
        //                         <a href='/analytics/' style={{borderRadius:20, marginRight:5, maxHeight:38}} className={(url[0]=='analytics'?'btn btn-outline-primary active':'btn btn-outline-primary')}>Analytics</a>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </>
        // )
        return (
            <div data-sidebar="dark" className={this.state.navBlock?'sidebar-enable tmbody':'tmbody'} >
                <div id="layout-wrapper" >
                    <header id="page-topbar">
                        <div className="navbar-header">
                            <div className="d-flex">
                                {/* <div className="navbar-brand-box">
                                    <a href="index.html" className="logo ">
                                        <span className="logo-sm">
                                            <img src={require('../components/img/logo-light.png')}></img>
                                        </span>
                                        <span className="logo-lg">
                                            <img src={require('../components/img/logo-light.png')}></img>
                                        </span>
                                    </a>
                                </div> */}
                                <div className="navbar-brand-box">
                                    <a href="/admin/main/" className="logo">
                                        {/* <span className="logo-sm">
                                            <img src={require('../components/img/logo-light.png')} alt="logo" height="18"></img>
                                        </span> */}
                                        <span className="logo-lg">
                                            <img src={(window.location.host.indexOf('localhost') == -1)?getApiDomain().replace('api', '')+'client/build/img/logo-light.png':require('../components/img/logo-light.png')} alt="" height="28"></img>
                                        </span>
                                    </a>
                                </div>
                                
                                <button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                                    <i onClick={()=>{this.setState({navBlock:!this.state.navBlock})}} className="material-icons subject">&#xe8d2;</i>
                                </button>
                                <div className="d-none d-sm-block">
                                    <div className="dropdown pt-3 d-inline-block">
                                        <span style={{fontSize:20}}><strong >{localStorage.getItem('page_name')}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                {/* <div className="dropdown d-none d-lg-inline-block">
                                    <button type="button" className="btn header-item noti-icon waves-effect" >
                                        <i className="material-icons subject">&#xe8d2;</i>
                                    </button>
                                </div> */}
                                
                                <div className="dropdown d-inline-block" style={{marginRight:10}}>
                                    {/* <span style={{fontSize:10}}>{this.state.dateTimeTimeZone.time}</span> */}
                                    <div style={{alignItems:'center'}} className='row header-item'>
                                        <div className='col text-center' style={{paddingBottom:8}}>
                                            <span style={{fontSize:10}}>{this.state.dateTimeTimeZone.timezone}</span>
                                            <hr style={{margin:0, padding:0, top:0}}></hr>
                                            <span style={{fontSize:14}}>{this.state.dateTimeTimeZone.date}</span>
                                        </div>
                                        <div className='col' style={{paddingBottom:8}}>
                                            <span style={{fontSize:30}}>{this.state.dateTimeTimeZone.time}</span>
                                        </div>
                                        {/* <div className='col'>
                                            <span  onClick={()=>{this.LogOut()}}><i className="material-icons power_settings_new c_p text-danger">&#xe8ac;</i></span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="dropdown d-inline-block">
                                    <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                                        <span onClick={()=>{this.LogOut()}}><i className="material-icons power_settings_new c_p text-danger">&#xe8ac;</i></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="vertical-menu">
                        <div data-simplebar className="h-100">
                            <div id="sidebar-menu">
                                <ul className="metismenu list-unstyled" id="side-menu">
                                    {this.state.navigation_list.map((item:any) => {
                                        let li = <li key={item.name} style={{paddingLeft:20, marginBottom:15}}><a  href={item.href} onMouseEnter={()=>{this.SetActiveNavItem(item.id)}} onMouseLeave={()=>{this.SetActiveNavItem(item.id)}} style={item.active?st.mNavItemHover:st.mNavItem}>{item.icon}<span style={{fontSize:18}}>{item.name}</span></a></li>;
                                        if(item.perm.length != 0){
                                            if(item.perm.indexOf(systemConfig.user_data.role) != -1){
                                                return li;
                                            }
                                            else{
                                                return null;
                                            }
                                        }
                                        else{
                                            return li;
                                        }
                                    })}
                                    {/* <li>
                                        <a href="javascript: void(0);" className="has-arrow waves-effect">
                                            <i className="ti-bookmark-alt"></i>
                                            <span>  Email Templates  </span>
                                        </a>
                                        <ul className="sub-menu" aria-expanded="false">
                                            <li><a href="email-template-basic.html">Basic Action Email</a></li>
                                            <li><a href="email-template-alert.html">Alert Email</a></li>
                                            <li><a href="email-template-billing.html">Billing Email</a></li>
                                        </ul>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="main-content">
                        <div className="page-content">
                            <div style={{marginTop:-10}}>
                                <br></br>
                                {/* <div className="row">
                                    <div className="col-xl-3 col-md-6">
                                        <div className="card mini-stat bg-primary text-white">
                                            <div className="card-body">
                                                <div className="mb-4">
                                                    <div className="float-start mini-stat-img me-4"></div>
                                                    <h5 className="font-size-16 text-uppercase text-white-50">Orders</h5>
                                                    <h4 className="fw-medium font-size-24">1,685 <i
                                                            className="mdi mdi-arrow-up text-success ms-2"></i></h4>
                                                    <div className="mini-stat-label bg-success">
                                                        <p className="mb-0">+ 12%</p>
                                                    </div>
                                                </div>
                                                <div className="pt-2">
                                                    <div className="float-end">
                                                        <a href="#" className="text-white-50"><i className="mdi mdi-arrow-right h5"></i></a>
                                                    </div>
                                                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

