
const stWhite: { [key: string]: React.CSSProperties } = {
    card: {
        position:'relative',
        //   display:'-ms-flexbox',
        display: 'flex',
        msFlexDirection:'column',
        flexDirection:'column',
        minWidth:0,
        wordWrap:'break-word',
        backgroundColor: '#fff',
        backgroundClip: 'border-box',
        //   border: '1px solid rgba(0, 0, 0, .125)',
        borderRadius: ".55rem",
        boxShadow: '1px 5px 19px -4px rgba(0,0,0,0.37)',
        WebkitBoxShadow: '1px 5px 19px -4px rgba(0,0,0,0.37)',
        
    },
    bgBody:{
        backgroundColor:'#f4f3f6'
    },
    hr:{
        backgroundColor:'#ababab',
        opacity:0.5
    },
    mNavItem:{
        boxShadow: '1px 5px 19px -4px rgba(0,0,0,0.37)',
        WebkitBoxShadow: '1px 5px 19px -4px rgba(0,0,0,0.37)',
        display:'flex', 
        justifyContent:'flex-start', 
        alignItems:'center', 
        borderLeft: '4px solid',
        borderColor:'#d4a630',
        borderTopLeftRadius:5,
        borderBottomLeftRadius:5,
        textDecoration:'none',
        color:'white',
        marginLeft:20
    },
    mNavItemHover:{
        boxShadow: '1px 5px 19px -4px rgba(0,0,0,0.37)',
        WebkitBoxShadow: '1px 5px 19px -4px rgba(0,0,0,0.37)',
        display:'flex', 
        justifyContent:'flex-start', 
        alignItems:'center', 
        borderLeft: '4px solid',
        borderColor:'#d4a630',
        borderTopLeftRadius:5,
        borderBottomLeftRadius:5,
        textDecoration:'none',
        color:'white',
        
    }
};

const stDark: { [key: string]: React.CSSProperties } = {
    card: {
      position:'relative',
    //   display:'-ms-flexbox',
      display: 'flex',
      msFlexDirection:'column',
      flexDirection:'column',
      minWidth:0,
      wordWrap:'break-word',
      backgroundColor: '#000',
      backgroundClip: 'border-box',
      border: '1px solid rgba(0, 0, 0, .125)',
      borderRadius: ".25rem"
    },
    bgBody:{
        backgroundColor:'#25283a'
    },
    hr:{
        backgroundColor:'#25283a'
    }
};



let theme = true;
export const st:any = theme ? stWhite: stDark;