import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate } from '../components/function';
import Select from 'react-select';



export default class CampaignsSelect extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        req_data:[],
        select_data:[],
        loading:true
      }
    }
  
    componentDidMount(){
        this.GetList();
    }

    GetList = () => {
        ApiReq('/campaigns/get_campaigns_list/').then(res => {
            if(res != false){
              if(res.success == true){
                let selectData:any = [];
                for(let i=0; i<res.data.length; i++){
                    selectData.push({label:res.data[i].name, value:res.data[i].id});
                }
                this.setState({
                    req_data:res.data,
                    select_data:selectData,
                    loading:false
                })
              }
              else{
                notySend('error', 'error #'+res.error+'\n'+res.info)
              }
            }
        })
    }

    Change = (res:any) => {
        if(this.props.onChange != null){
            let req_data = this.state.req_data;
            if(this.props.dynamicFilter != null && res.length > 0){
                let more_data:any = [];
                for(let i=0; i<res.length; i++){
                    for(let j=0; j<req_data.length; j++){
                        if(res[i].value == req_data[j].id){
                            res[i].data = req_data[j];
                        }
                    }
                }
            }
            this.props.onChange(res);
        }
    }

    GetValues = (array:any) => {
        let newArray:any = [];
        for(let i=0; i<array.length; i++){
            newArray.push(array[i].value);
        }
        return newArray;
    }

    DynamicFilter = () => {
        let dynamicFilter = this.props.dynamicFilter;
        if(dynamicFilter != null){
            this.setState({loading:false, select_data:[]})
            let selectData:any = [];
            if(dynamicFilter.params.length > 0){
                for(let i=0; i<this.state.req_data.length; i++){
                    let check = false;
                    for(let j=0; j<dynamicFilter.params.length; j++){
                        let temp = this.GetValues(dynamicFilter.data[j]);
                        if(temp.length > 0){
                            for(let k=0; k<temp.length; k++){
                                if(String(this.state.req_data[i][dynamicFilter.params[j]]) == String(temp[k])){
                                    check = true;
                                    break;
                                }
                            }
                        }
                        else{
                            selectData.push({label:this.state.req_data[i].name, value:this.state.req_data[i].id});
                        }
                        if(check == true){
                            selectData.push({label:this.state.req_data[i].name, value:this.state.req_data[i].id});
                        }
                    }
                }
            }
            else{
                for(let i=0; i<this.state.req_data.length; i++){
                    selectData.push({label:this.state.req_data[i].name, value:this.state.req_data[i].id});
                }
            }
            
            this.setState({
                select_data:selectData,
                loading:false
            })
        }
    }
    
  
    render() {
        if(this.props.isMulti == true){
            return (
                <Select onFocus={()=>{this.DynamicFilter()}} value={this.props.value} isLoading={this.state.loading} isMulti={true} onChange={(res)=>{this.Change(res)}}  options={this.state.select_data} />
            )
        }
        else{
            return (
                <Select value={this.props.value} isLoading={this.state.loading}  onChange={(res)=>{this.Change(res)}}  options={this.state.select_data} />
            )
        }
    }
}

