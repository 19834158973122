import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate } from '../components/function';
import Select from 'react-select';

let select_data = [{label:'admin', value:1}, {label:'team_lead', value:2}, {label:'buyer', value:3}];

export default class RolesSelect extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        select_data:select_data,
        value:this.props.value
      }
    }
  
    componentDidMount(){
      
      if(this.props.role != null){
        let r_select_data:any = [];
        for(let i=0; i<select_data.length; i++){
          if(this.props.role.indexOf(select_data[i].label) != -1){
            r_select_data.push(select_data[i])
          }
        }
        this.setState({
          select_data:r_select_data
        })
      }

    }

    Change = (res:any) => {
        if(this.props.onChange != null){
            this.props.onChange(res);
        }
        this.setState({
          value:res
        })
    }
  
    render() {
      return (
        <Select value={this.state.value}  onChange={(res)=>{this.Change(res)}}  options={this.state.select_data} />
      )
    }

   
}

