import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate } from '../components/function';
import Select from 'react-select';



export default class FbAccountsSelect extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        start_data:[],
        select_data:[],
        loading:true
      }
    }
  
    componentDidMount(){
        this.GetList();
    }

    GetList = () => {
        ApiReq('/fb_accounts/get_fb_accounts_list/').then(res => {
            if(res != false){
              if(res.success == true){
                let selectData:any = [];
                let start_data:any = [];
                for(let i=0; i<res.data.length; i++){
                    if(res.data[i].status == true){
                        start_data.push(res.data[i])
                        selectData.push({label:res.data[i].aid, value:res.data[i].id});
                    }
                }
                this.setState({
                    select_data:selectData,
                    start_data:start_data,
                    loading:false
                })
              }
              else{
                notySend('error', 'error #'+res.error+'\n'+res.info)
              }
            }
        })
    }

    Change = (res:any) => {
        if(this.props.onChange != null){
            this.props.onChange(res);
        }
        // this.setState({
        //   value:res
        // })
    }

    DynamicFilter = () => {
      let filter_select_data:any = [];
      let select_data = this.state.start_data;
      // console.log(select_data)
      // console.log(this.props.buyer)
      if(this.props.buyer != null){
        if(this.props.isMulti == true){
          if(this.props.buyer.length > 0){
            let buyers:any = [];
            for(let i=0; i<this.props.buyer.length; i++){
              buyers.push(this.props.buyer[i].value);
            }
  
            for(let i=0; i<select_data.length; i++){
              if(buyers.indexOf(select_data[i].buyer_id) != -1){
                filter_select_data.push({label:select_data[i].aid, value:select_data[i].id});
              }
            }
          }
          else{
            for(let i=0; i<select_data.length; i++){
              filter_select_data.push({label:select_data[i].aid, value:select_data[i].id});
            }
          }
        }
        else{
          for(let i=0; i<select_data.length; i++){
            if(select_data[i].buyer_id == this.props.buyer.value){
              filter_select_data.push({label:select_data[i].aid, value:select_data[i].id});
            }
          }
        }
        
      }
      else{
        for(let i=0; i<select_data.length; i++){
          filter_select_data.push({label:select_data[i].aid, value:select_data[i].id});
        }
      }
      this.setState({select_data:filter_select_data})
    }
  
    render() {
        if(this.props.isMulti == true){
          return (
              <Select onFocus={()=>{this.DynamicFilter()}} value={this.props.value} isLoading={this.state.loading} isMulti={true} onChange={(res)=>{this.Change(res)}}  options={this.state.select_data} />
            )
        }
        else{
            return (
                <Select onFocus={()=>{this.DynamicFilter()}} value={this.props.value} isLoading={this.state.loading}  onChange={(res)=>{this.Change(res)}}  options={this.state.select_data} />
            )
        }
    }

   
}

