import React, { Component } from 'react';
import { IonContent } from '@ionic/react';
import { st } from '../components/css/st';



export default class Page404 extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
         
      }
    }
  
    componentDidMount(){
       

    }


    

  
    render() {
        return(
            <div style={{position:'absolute',  width:400, left:'50%', marginLeft:-200, top:'30%'}}>
                <div style={st.card}>
                    <div className='card-body'>
                        <div>
                            <h1 className='text-center text-primary'>404</h1>
                            <h1 className='text-center text-primary'>page not found</h1>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

