import React, { Component } from 'react';
import { IonContent } from '@ionic/react';
import { st } from '../components/css/st';
import { getSystemConfig } from '../components/function';
import CustomModal from '../components/CustomModal';

let systemConfig = getSystemConfig();

export default class MainPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
         
      }
    }
  
    componentDidMount(){
       

    }


    

  
    render() {
        return(
            <div style={st.card}>
                
                <div className='card-body'>
                    <h4>{systemConfig.user_data.login} - Welcome to analytics CRM</h4>
                </div>
            </div>
        )
    }
}

