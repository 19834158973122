import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate } from '../components/function';
import Select from 'react-select';



export default class AgencySelect extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        select_data:[],
        loading:true
      }
    }
  
    componentDidMount(){
        this.GetAgencyList();
    }

    GetAgencyList = () => {
        ApiReq('/agency/get_agency_list/').then(res => {
            if(res != false){
              if(res.success == true){
                let selectData:any = [];
                for(let i=0; i<res.data.length; i++){
                    selectData.push({label:res.data[i].name, value:res.data[i].id});
                }
                this.setState({
                    select_data:selectData,
                    loading:false
                })
              }
              else{
                notySend('error', 'error #'+res.error+'\n'+res.info)
              }
            }
        })
    }

    Change = (res:any) => {
        if(this.props.onChange != null){
            this.props.onChange(res);
        }
        // this.setState({
        //   value:res
        // })
    }

    
  
    render() {
      return (
        <Select value={this.props.value} isLoading={this.state.loading} onChange={(res)=>{this.Change(res)}}  options={this.state.select_data} />
      )
    }

   
}

