import React, { Component } from 'react';
import { IonContent } from '@ionic/react';
import { st } from '../components/css/st';
import { ApiReq, notySend, urlNavigate, getBtnLoader, generateToken, getSystemConfig } from '../components/function';
import PageLoader from '../components/PageLoader';
import RolesSelect from '../components/RolesSelect';
import TimeZonesSelect from '../components/TimeZonesSelect';
import UsersSelect from '../components/UsersSelect';

let systemConfig = getSystemConfig();

export default class UsersPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        render:'users',
        loader:true,
        table_data:[],
        add_user_login:'',
        add_user_role:{label:'admin', value:1},
        add_user_team_lead:null,
        add_user_salary:0,
        add_user_timezone:null,
        add_user_password:generateToken(),
        add_user_loader:false,
        edit_user_id:null,
        edit_user_login:'',
        edit_user_role:null,
        edit_user_timezone:null,
        edit_user_salary:0,
        edit_user_reset_password:false,
        edit_user_password:generateToken(),
        edit_user_created_by_user:null,
        edit_user_team_lead:null,
        edit_user_loader:false,
      }
    }
  
    componentDidMount(){
       this.GetUsersList();
    }

    GetUsersList = () => {
        ApiReq('/users/get_users/').then(res => {
            if(res != false){
              if(res.success == true){
                this.setState({
                  table_data:res.data,
                  loader:false
                })
              }
              else{
                notySend('error', 'error #'+res.error+'\n'+res.info)
              }
            }
        })
    }

    AddUser = () => {
        if(this.state.add_user_login != '' && this.state.add_user_password != null && this.state.add_user_timezone != null){
            this.setState({
                add_user_loader:true
            })
            ApiReq('/users/add_user/', {login:this.state.add_user_login, timezone:this.state.add_user_timezone.value, password:this.state.add_user_password, role:this.state.add_user_role.value, salary:this.state.add_user_salary, created_by_user:getSystemConfig().user_data.user_id, team_lead:this.state.add_user_team_lead}).then(res => {
                if(res != false){
                    this.setState({
                        add_user_loader:false
                    })
                    if(res.success == true){
                        notySend('success', 'success')
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n'+res.info);
                    }
                }
            })
        }
        else{
            notySend('error', 'all params must not be empty!')
        }
    }

    EditUser = () => {
        if(this.state.edit_user_login != '' && this.state.edit_user_password != '' && this.state.edit_user_id != null, this.state.edit_user_timezone != null){
            let timezone = this.state.edit_user_timezone;
            if(this.state.edit_user_timezone.value != null){
                timezone = this.state.edit_user_timezone.value;
            }
            this.setState({
                edit_user_loader:true
            })
            
            ApiReq('/users/update_user/', {user_id:this.state.edit_user_id, login:this.state.edit_user_login, timezone:timezone, reset_password:this.state.edit_user_reset_password, password:this.state.edit_user_password, role:this.state.edit_user_role.value, salary:this.state.edit_user_salary, team_lead:this.state.edit_user_team_lead}).then(res => {
                if(res != false){
                    this.setState({
                        edit_user_loader:false
                    })
                    if(res.success == true){
                        notySend('success', 'success')
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n'+res.info);
                    }
                }
            })
        }
        else{
            notySend('error', 'all params must not be empty!')
        }
    } 

    DeleteUser = () => {
        if(this.state.edit_user_id != null){
            this.setState({
                edit_user_loader:true
            })
            ApiReq('/users/delete_user/', {user_id:this.state.edit_user_id}).then(res => {
                if(res != false){
                    this.setState({
                        edit_user_loader:false,
                    })
                    if(res.success == true){
                        this.GetUsersList();
                        this.setState({
                            render:'users'
                        })
                        notySend('success', 'success')
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n'+res.info);
                    }
                }
            })
        }
        else{
            notySend('error', 'param user_id must not be empty!')
        }
    }

    CorrectRole = (role:string) => {
        switch (role) {
            case 'admin':
                return {label:'admin', value:1}
            case 'team_lead':
                return {label:'team_lead', value:2}
            case 'buyer':
                return {label:'buyer', value:3}
        }
    }

    RenderTable = () => {
        if(this.state.loader == true){
          return <div className='text-center'><PageLoader></PageLoader></div>;
        }
        else{
          return (
            <div className='table-responsive'>
              <table className="table">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>login</th>
                    <th>role</th>
                    <th>time zone</th>
                    <th>salary %</th>
                    <th>show</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.table_data.map((item:any) => {
                    return (
                      <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.login}</td>
                          <td>{item.role}</td>
                          <td>{item.timezone}</td>
                          <td>{item.salary} %</td>
                          <td><i onClick={()=>{this.setState({render:'edit_user', edit_user_login:item.login, edit_user_salary:item.salary, edit_user_timezone:item.timezone, edit_user_role:this.CorrectRole(item.role), edit_user_id:item.id, edit_user_created_by_user:item.created_by_user, edit_user_team_lead:item.team_lead})}} className="material-icons remove_red_eye c_p text-primary">&#xe417;</i></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )
        }
    }

    RenderInputByUserRole = () => {
        if(this.state.add_user_role.label == 'buyer'){
            return(
                <>
                    <label>team lead</label>
                    <UsersSelect onChange={(t:any)=>{this.setState({add_user_team_lead:t})}} role={'team_lead'} created_by_user={systemConfig.user_data.user_id}></UsersSelect>
                </>
            )
        }
    }
    
    RenderAddUserForm = () => {
        return(
            <div>
                <div className='form-group'>
                    <label>login</label>
                    <input onInput={(t)=>{this.setState({add_user_login:t.currentTarget.value})}} placeholder='Write user login' className='form-control'></input>
                    <label>time zone</label>
                    <TimeZonesSelect onChange={(t:any)=>{this.setState({add_user_timezone:t})}}></TimeZonesSelect>
                    <label>role</label>
                    <RolesSelect role={['admin', 'buyer']} onChange={(t:any)=>{this.setState({add_user_role:t, add_user_team_lead:null})}} value={this.state.add_user_role}></RolesSelect>
                    {this.RenderInputByUserRole()}
                    <label>salary %</label>
                    <input onInput={(t)=>{this.setState({add_user_salary:t.currentTarget.value})}} value={this.state.add_user_salary} className='form-control' type='number'></input>
                    <label>password</label>
                    <input placeholder='Write user password' onInput={(t)=>{this.setState({add_user_password:t.currentTarget.value})}} value={this.state.add_user_password} className='form-control'></input>
                </div>
                <button onClick={()=>{this.AddUser()}} disabled={this.state.add_user_loader} className='btn btn-primary form-control'>{this.state.add_user_loader?getBtnLoader():null} add user</button>
            </div>
        )
    }

    RenderEditUserForm = () => {
        return(
            <div>
                <div className='form-group'>
                    <label>login</label>
                    <input onInput={(t)=>{this.setState({edit_user_login:t.currentTarget.value})}} value={this.state.edit_user_login} placeholder='Write user login' className='form-control'></input>
                    <label>role</label>
                    <RolesSelect role={(this.state.edit_user_created_by_user == systemConfig.user_data.user_id)?['admin', 'team_lead', 'buyer']:['admin', 'buyer']} onChange={(t:any)=>{this.setState({edit_user_role:t})}} value={this.state.edit_user_role}></RolesSelect>
                    <label>time zone</label>
                    <TimeZonesSelect value={this.state.edit_user_timezone} onChange={(t:any)=>{this.setState({edit_user_timezone:t})}}></TimeZonesSelect>
                    {this.state.edit_user_role.label == 'buyer'?<><label>team lead</label><UsersSelect value={this.state.edit_user_team_lead} onChange={(t:any)=>{this.setState({edit_user_team_lead:t})}} role={'team_lead'} created_by_user={systemConfig.user_data.user_id}></UsersSelect></>:null}
                    <label>salary %</label>
                    <input onInput={(t)=>{this.setState({edit_user_salary:t.currentTarget.value})}} value={this.state.edit_user_salary} className='form-control' type='number'></input>
                    <div style={{display:'flex', justifyContent:'left'}}>{this.state.edit_user_reset_password ? <i onClick={()=>{this.setState({edit_user_reset_password:false})}} className="material-icons check_box text-primary c_p">&#xe834;</i>:<i onClick={()=>{this.setState({edit_user_reset_password:true})}} className="material-icons check_box_outline_blank text-primary c_p">&#xe835;</i>} update password</div>
                    {this.state.edit_user_reset_password ?<><label>new password</label><input placeholder='Write user password' onInput={(t)=>{this.setState({edit_user_password:t.currentTarget.value})}} value={this.state.edit_user_password} className='form-control'></input></>:null}
                </div>
                <button onClick={()=>{this.EditUser()}} disabled={this.state.edit_user_loader} className='btn btn-primary form-control'>{this.state.edit_user_loader?getBtnLoader():null} edit user</button>
                <hr style={st.hr}></hr>
                <button onClick={()=>{this.DeleteUser()}} disabled={this.state.edit_user_loader} className='btn btn-danger form-control'>{this.state.edit_user_loader?getBtnLoader():null} delete user</button>
            </div>
        )
    }
  
    render() {
        return(
            <div style={st.card}>
                <div className='card-body'>
                    <div className='form-group'>
                        <button onClick={()=>{this.setState({render:'users'});this.GetUsersList();}} style={{marginRight:5}} className={(this.state.render == 'users')? 'btn btn-primary':'btn btn-outline-primary'}>users list</button>
                        <button onClick={()=>{this.setState({render:'add_user'})}} style={{marginRight:5}} className={(this.state.render == 'add_user')? 'btn btn-primary':'btn btn-outline-primary'}>add user</button>
                    </div>
                    <hr style={st.hr}></hr>
                    {(this.state.render == 'users')?this.RenderTable():null}
                    {(this.state.render == 'add_user')?this.RenderAddUserForm():null}
                    {(this.state.render == 'edit_user')?this.RenderEditUserForm():null}
                </div>
            </div>
        )
    }
}

