import React, { Component } from 'react';
import { IonContent } from '@ionic/react';
import { st } from '../components/css/st';
import { ApiReq, notySend, urlNavigate, getBtnLoader, generateToken, parsGetParams, getSystemConfig, getDateTimeZoneFromUnix } from '../components/function';
import PageLoader from '../components/PageLoader';
import OffersSelect from '../components/OffersSelect';
import FbAccountsSelect from '../components/FbAccountsSelect';
import CustomModal from '../components/CustomModal';
import moment from 'moment-timezone';
import PartnersSelect from '../components/PartnersSelect';
import UsersSelect from '../components/UsersSelect';
import Select from 'react-select';

let getParams = parsGetParams(window.location.href);
let systemConfig = getSystemConfig();

export default class CampaignsPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        render:'campaigns_list',
        loader:true,
        table_data:[],
        start_table_data:[],
        current_timezone:systemConfig.user_data.timezone,
        add_campaign_name:'',
        add_campaign_campaign_id:'',
        add_campaign_fb_account:null,
        add_campaign_offer:[],
        add_campaign_partner:[],
        add_campaign_expenses:0,
        add_campaign_loader:false,

        edit_modal:false,
        edit_modal_tab:[{id:0, name:'campaign', active:true}, {id:1, name:'expenses/clicks', active:false}],
        edit_campaign_id:0,
        edit_campaign_name:'',
        edit_campaign_campaign_id:'',
        edit_campaign_fb_account:null,
        edit_campaign_status:{label:'true', value:true},
        edit_campaign_fb_account_timezone:'',
        edit_campaign_offer:[],
        edit_campaign_partner:[],
        edit_campaign_expenses:0,
        edit_campaign_currency:'',
        edit_campaign_loader:false,

        campaign_expenses:[],
        campaign_expenses_loader:true,
        add_campaign_expenses_loader:false,
        add_campaign_expenses_date:'',
        add_campaign_expenses_count:'',

        campaign_clicks:[],
        campaign_clicks_loader:true,
        add_campaign_clicks_loader:false,
        add_campaign_clicks_date:'',
        add_campaign_clicks_count:'',

        filter_fb_account:null,
        filter_buyer:null,
        filter_search:''
      }
    }
  
    componentDidMount(){
        
        // console.log(moment.utc(moment.tz('2023-11-13 21:15', 'Europe/Kyiv').unix(), 'X').format('YYYY-MM-DD HH:mm'))
        this.GetCampaignsList();
    }

    GetCampaignsList = () => {
        let dop_api_url = "";
        if(getParams != false && getParams.id != null){
            dop_api_url="?id="+getParams.id;
        }
        ApiReq('/campaigns/get_campaigns_list/'+dop_api_url).then(res => {
            if(res != false){
              if(res.success == true){
                //console.log(res)
                this.setState({
                    table_data:res.data,
                    start_table_data:res.data,
                    loader:false,
                })
                setTimeout(()=>{
                    this.Filter();
                }, 100)
              }
              else{
                notySend('error', 'error #'+res.error+'\n'+res.info)
              }
            }
        })
    }

    AddCampaign = () => {
        if(this.state.add_campaign_campaign_id != '' && this.state.add_campaign_fb_account != null && this.state.add_campaign_offer.length != 0 && this.state.add_campaign_partner.length != 0){
            this.setState({
                add_campaign_loader:true
            })
            
            ApiReq('/campaigns/add_campaign/', {campaign_id:this.state.add_campaign_campaign_id, fb_account:this.state.add_campaign_fb_account.value, offer:this.state.add_campaign_offer, partner:this.state.add_campaign_partner}).then(res => {
                if(res != false){
                    this.setState({
                        add_campaign_loader:false
                    })
                    if(res.success == true){
                        notySend('success', 'success')
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n'+res.info);
                    }
                }
            })
        }
        else{
            notySend('error', 'all params must not be empty!')
        }
    }

    EditCampaign = () => {
        if(this.state.edit_campaign_id != 0 && this.state.edit_campaign_campaign_id != '' && this.state.edit_campaign_offer.length != 0 && this.state.edit_campaign_partner.length != 0){
            ApiReq('/campaigns/update_campaign/', {id:this.state.edit_campaign_id, campaign_id:this.state.edit_campaign_campaign_id, fb_account:this.state.edit_campaign_fb_account.value, offer:this.state.edit_campaign_offer, partner:this.state.edit_campaign_partner, status:this.state.edit_campaign_status.value}).then(res => {
                if(res != false){
                    if(res.success == true){
                        notySend('success', 'success')
                        this.GetCampaignsList();
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n'+res.info);
                    }
                }
            })
        }
        else{
            notySend('error', 'all params must not be empty!')
        }
        
    } 

    DeleteCampaign = () => {
        console.log({id:this.state.edit_campaign_id})
        ApiReq('/campaigns/delete_campaign/', {id:this.state.edit_campaign_id}).then(res => {
            if(res != false){
                if(res.success == true){
                    notySend('success', 'success')
                    this.GetCampaignsList();
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
        })
    }



    RenderTable = () => {
        if(this.state.loader == true){
          return <div className='text-center'><PageLoader></PageLoader></div>;
        }
        else{
          return (
            <div className='table-responsive'>
              <table className="table">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>name</th>
                    <th>campaign_id</th>
                    <th>fb_account (aid)</th>
                    {/* <th>offer</th> */}
                    <th>buyer</th>
                    <th>expenses</th>
                    <th>clicks</th>
                    <th>income</th>
                    <th>leads</th>
                    <th>status</th>
                    <th>show</th>
                    <th>copy</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.table_data.map((item:any) => {
                    return (
                      <tr key={item.id}>
                          <td>{item.id}</td>
                          <td style={{maxWidth:200}}>{item.name}</td>
                          <td>{item.campaign_id}</td>
                          <td>{item.fb_account}</td>
                          {/* <td>{item.offer}</td> */}
                          <td>{item.fb_account_buyer}</td>
                          <td>{Math.round(item.expenses)}</td>
                          <td>{item.clicks}</td>
                          <td>{Math.round(item.income)}</td>
                          <td>{item.leads}</td>
                          <td>{item.campaign_status?<span style={{opacity:0.5}} className="badge badge-success">true</span>:<span style={{opacity:0.5}} className="badge badge-danger">false</span>}</td>
                          <td>
                            <span><i onClick={()=>{this.setState({edit_modal:true, edit_campaign_id:item.id, edit_campaign_name:item.name, edit_campaign_campaign_id:item.campaign_id, edit_campaign_fb_account:{label:item.fb_account, value:item.fb_account_id}, edit_campaign_offer:item.offer, edit_campaign_partner:item.partner, edit_campaign_fb_account_timezone:item.fb_account_timezone, current_timezone:item.fb_account_timezone, edit_campaign_currency:item.currency, edit_campaign_status:{label:String(item.campaign_status), value:item.campaign_status}})}} className="material-icons remove_red_eye c_p text-primary">&#xe417;</i></span>
                            <span><i onClick={()=>{this.setState({edit_modal:true, edit_modal_tab:[{id:0, name:'campaign', active:false}, {id:1, name:'expenses/clicks', active:true}], edit_campaign_name:item.name, edit_campaign_id:item.id, edit_campaign_campaign_id:item.campaign_id, edit_campaign_fb_account:{label:item.fb_account, value:item.fb_account_id}, edit_campaign_offer:item.offer, edit_campaign_partner:item.partner, edit_campaign_fb_account_timezone:item.fb_account_timezone, current_timezone:item.fb_account_timezone, edit_campaign_currency:item.currency, edit_campaign_status:{label:String(item.campaign_status), value:item.campaign_status}});setTimeout(()=>{this.GetCampaignExpenses();},200)}} className="material-icons history_edu c_p text-primary">&#xea3e;</i></span>
                          </td>
                          <td><span><i onClick={()=>{this.setState({render:'add_campaign', add_campaign_fb_account:{label:item.fb_account, value:item.fb_account_id},  add_campaign_offer:item.offer, add_campaign_partner:item.partner})}} className="material-icons content_copy c_p text-primary">&#xe14d;</i></span></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )
        }
    }
    
    RenderAddCampaignForm = () => {
        return(
            <div>
                <div className='form-group'>
                    <label>fb account</label>
                    <FbAccountsSelect value={this.state.add_campaign_fb_account} onChange={(t:any)=>{this.setState({add_campaign_fb_account:t})}}></FbAccountsSelect>
                    <label>offer</label>
                    <OffersSelect value={this.state.add_campaign_offer} isMulti={true} onChange={(t:any)=>{this.setState({add_campaign_offer:t})}}></OffersSelect>
                    <label>partner</label>
                    <PartnersSelect value={this.state.add_campaign_partner} isMulti={true} onChange={(t:any)=>{this.setState({add_campaign_partner:t})}}></PartnersSelect>
                    <label>campaign_id</label>
                    <input onInput={(t)=>{this.setState({add_campaign_campaign_id:t.currentTarget.value})}} value={this.state.add_campaign_campaign_id} className='form-control' type='number'></input>
                </div>
                <button onClick={()=>{this.AddCampaign()}} disabled={this.state.add_campaign_loader} className='btn btn-primary form-control'>{this.state.add_campaign_loader?getBtnLoader():null} add campaign</button>
            </div>
        )
    }


    SetEditModalTab = (id:number) => {
        let edit_modal_tab = this.state.edit_modal_tab;
        for(let i=0; i<edit_modal_tab.length; i++){
            if(edit_modal_tab[i].id == id){
                edit_modal_tab[i].active = true;
            }
            else{
                edit_modal_tab[i].active = false;
            }
        }

        if(id == 1){
            this.GetCampaignExpenses();
        }
        if(id == 2){
            this.GetCampaignClicks();
        }

        this.setState({edit_modal_tab:edit_modal_tab});
    }

    //for expenses
    GetCampaignExpenses = () => {
        this.setState({
            campaign_expenses_loader:true
        })
        ApiReq('/campaigns/expenses/', {event:'get', campaign_id:this.state.edit_campaign_id}).then(res => {
            if(res != false){
              if(res.success == true){
                let campaign_expenses:any = [];
                for(let i=0; i<res.data.length; i++){
                    campaign_expenses.push({id:res.data[i].id, campaign:res.data[i].campaign, date_unixtime:res.data[i].date_unixtime, date_unixtime_convert:getDateTimeZoneFromUnix(Number(res.data[i].date_unixtime), this.state.current_timezone), count:res.data[i].count, clicks_id:res.data[i].clicks_id, clicks_count:res.data[i].clicks_count, edit_loader:false});
                }
                this.setState({
                    campaign_expenses:campaign_expenses,
                    campaign_expenses_loader:false,
                })
              }
              else{
                notySend('error', 'error #'+res.error+'\n'+res.info)
              }
            }
        })
    }

    AddCampaignExpenses = () => {
        if(this.state.add_campaign_expenses_date != '' && this.state.add_campaign_expenses_count != '' && this.state.add_campaign_clicks_count != ''){
            this.setState({
                add_campaign_expenses_loader:true
            })
            ApiReq('/campaigns/expenses/', {event:'add', campaign_id:this.state.edit_campaign_id, date_unixtime:moment.tz(this.state.add_campaign_expenses_date, this.state.current_timezone).unix(), count:this.state.add_campaign_expenses_count, currency:this.state.edit_campaign_currency, offer:this.state.edit_campaign_offer, partner:this.state.edit_campaign_partner, timezone:this.state.current_timezone}).then(res => {
                if(res != false){
                    this.setState({
                        add_campaign_expenses_loader:false
                    })
                    if(res.success == true){
                        this.AddCampaignClicks();
                        //this.GetCampaignExpenses();
                        //notySend('success', 'success')
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n'+res.info)
                    }
                }
            })
        }
        else{
            notySend('error', 'all params must not be empty!');
        }
    }

    UpdateListCampaignExpenses = (id:number, count:any, param:string='expenses') => {
        let campaign_expenses = this.state.campaign_expenses;
        for(let i=0; i<campaign_expenses.length; i++){
            if(param == 'expenses'){
                if(campaign_expenses[i].id == id){
                    campaign_expenses[i].count = count;
                    break;
                }
            }
            else{
                if(campaign_expenses[i].clicks_id == id){
                    campaign_expenses[i].clicks_count = count;
                    break;
                }
            }
        }
        //console.log(campaign_expenses)
        this.setState({
            campaign_expenses:campaign_expenses
        })
    }

    UpdateCampaignExpenses = (item:any) => {
        let campaign_expenses = this.state.campaign_expenses;
        let index:any;
        for(let i=0; i<campaign_expenses.length; i++){
            if(campaign_expenses[i].id == item.id){
                campaign_expenses[i].edit_loader = true;
                index = i;
                break;
            }
        }
        this.setState({
            campaign_expenses:campaign_expenses
        })
        ApiReq('/campaigns/expenses/', {event:'update', expenses_id:item.id,  count:item.count}).then(res => {
            if(res != false){
                campaign_expenses[index].edit_loader = false;
                this.setState({
                    campaign_expenses:campaign_expenses
                })
                if(res.success == true){
                    ApiReq('/campaigns/clicks/', {event:'update', clicks_id:item.clicks_id,  count:item.clicks_count}).then(res => {
                        if(res != false){
                            if(res.success == true){
                                campaign_expenses[index].edit_loader = false;
                                this.setState({
                                    campaign_expenses:campaign_expenses
                                })
                                notySend('success', 'success')
                            }
                            else{
                                notySend('error', 'error #'+res.error+'\n'+res.info)
                            }
                        }
                    })
                    // notySend('success', 'success')
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info)
                }
            }
        })
    }

    DeleteCampaignExpenses = (item:any) => {
        let campaign_expenses = this.state.campaign_expenses;
        let index:any;
        for(let i=0; i<campaign_expenses.length; i++){
            if(campaign_expenses[i].id == item.id){
                campaign_expenses[i].edit_loader = true;
                index = i;
                break;
            }
        }
        this.setState({
            campaign_expenses:campaign_expenses
        })
        ApiReq('/campaigns/expenses/', {event:'delete', expenses_id:item.id}).then(res => {
            if(res != false){
                campaign_expenses[index].edit_loader = false;
                this.setState({
                    campaign_expenses:campaign_expenses
                })
                if(res.success == true){
                    ApiReq('/campaigns/clicks/', {event:'delete', clicks_id:item.clicks_id}).then(res => {
                        if(res != false){
                            if(res.success == true){
                                campaign_expenses.splice(index, 1)
                                this.setState({
                                    campaign_expenses:campaign_expenses
                                })
                                notySend('success', 'success')
                            }
                            else{
                                notySend('error', 'error #'+res.error+'\n'+res.info)
                            }
                        }
                    })
                    //notySend('success', 'success')
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info)
                }
            }
        })
    }


    //for clicks
    GetCampaignClicks = () => {
        // this.setState({
        //     campaign_clicks_loader:true
        // })
        // ApiReq('/campaigns/clicks/', {event:'get', campaign_id:this.state.edit_campaign_id}).then(res => {
        //     if(res != false){
        //       if(res.success == true){
        //         //console.log(res)
        //         let campaign_clicks:any = [];
        //         for(let i=0; i<res.data.length; i++){
        //             campaign_clicks.push({id:res.data[i].id, campaign:res.data[i].campaign, date_unixtime:res.data[i].date_unixtime, date_unixtime_convert:getDateTimeZoneFromUnix(Number(res.data[i].date_unixtime), this.state.current_timezone), count:res.data[i].count, edit_loader:false});
        //         }
        //         this.setState({
        //             campaign_clicks:campaign_clicks,
        //             campaign_clicks_loader:false,
        //         })
        //       }
        //       else{
        //         notySend('error', 'error #'+res.error+'\n'+res.info)
        //       }
        //     }
        // })
    }

    AddCampaignClicks = () => {
        if(this.state.add_campaign_expenses_date != '' && this.state.add_campaign_clicks_count != ''){
            this.setState({
                add_campaign_expenses_loader:true
            })
            ApiReq('/campaigns/clicks/', {event:'add', campaign_id:this.state.edit_campaign_id, date_unixtime:moment.tz(this.state.add_campaign_expenses_date, this.state.current_timezone).unix(), count:this.state.add_campaign_clicks_count, offer:this.state.edit_campaign_offer, partner:this.state.edit_campaign_partner, timezone:this.state.current_timezone}).then(res => {
                if(res != false){
                    this.setState({
                        add_campaign_expenses_loader:false
                    })
                    if(res.success == true){
                        // this.GetCampaignClicks();
                        this.GetCampaignExpenses();
                        notySend('success', 'success')
                    }
                    else{
                        notySend('error', 'error #'+res.error+'\n'+res.info)
                    }
                }
            })
        }
        else{
            notySend('error', 'all params must not be empty!');
        }
    }

    UpdateListCampaignClicks = (id:number, count:any) => {
        // let campaign_clicks = this.state.campaign_clicks;
        // for(let i=0; i<campaign_clicks.length; i++){
        //     if(campaign_clicks[i].id == id){
        //         campaign_clicks[i].count = count;
        //         break;
        //     }
        // }
        // this.setState({
        //     campaign_clicks:campaign_clicks
        // })
    }

    UpdateCampaignClicks = (item:any) => {
        // let campaign_clicks = this.state.campaign_clicks;
        // let index:any;
        // for(let i=0; i<campaign_clicks.length; i++){
        //     if(campaign_clicks[i].id == item.id){
        //         campaign_clicks[i].edit_loader = true;
        //         index = i;
        //         break;
        //     }
        // }
        // this.setState({
        //     campaign_clicks:campaign_clicks
        // })
        // ApiReq('/campaigns/clicks/', {event:'update', clicks_id:item.id,  count:item.count}).then(res => {
        //     if(res != false){
        //         campaign_clicks[index].edit_loader = false;
        //         this.setState({
        //             campaign_clicks:campaign_clicks
        //         })
        //         if(res.success == true){
        //             notySend('success', 'success')
        //         }
        //         else{
        //             notySend('error', 'error #'+res.error+'\n'+res.info)
        //         }
        //     }
        // })
    }

    DeleteCampaignClicks = (item:any) => {
        // let campaign_clicks = this.state.campaign_clicks;
        // let index:any;
        // for(let i=0; i<campaign_clicks.length; i++){
        //     if(campaign_clicks[i].id == item.id){
        //         campaign_clicks[i].edit_loader = true;
        //         index = i;
        //         break;
        //     }
        // }
        // this.setState({
        //     campaign_clicks:campaign_clicks
        // })
        // ApiReq('/campaigns/clicks/', {event:'delete', clicks_id:item.id}).then(res => {
        //     if(res != false){
        //         campaign_clicks[index].edit_loader = false;
        //         this.setState({
        //             campaign_clicks:campaign_clicks
        //         })
        //         if(res.success == true){
        //             campaign_clicks.splice(index, 1)
        //             this.setState({
        //                 campaign_clicks:campaign_clicks
        //             })
        //             notySend('success', 'success')
        //         }
        //         else{
        //             notySend('error', 'error #'+res.error+'\n'+res.info)
        //         }
        //     }
        // })
    }

    ConvertUnixTimeWithTimezone = (list:string) => {
        // if(list == 'expenses'){
        //     let campaign_expenses = this.state.campaign_expenses;
        //     for(let i=0; i<campaign_expenses.length; i++){
        //         campaign_expenses[i].date_unixtime_convert = getDateTimeZoneFromUnix(Number(campaign_expenses[i].date_unixtime), this.state.current_timezone);
        //     }
        //     this.setState({
        //         campaign_expenses:campaign_expenses
        //     })
        // }
        let campaign_expenses = this.state.campaign_expenses;
        for(let i=0; i<campaign_expenses.length; i++){
            campaign_expenses[i].date_unixtime_convert = getDateTimeZoneFromUnix(Number(campaign_expenses[i].date_unixtime), this.state.current_timezone);
        }
        this.setState({
            campaign_expenses:campaign_expenses
        })

        let campaign_clicks = this.state.campaign_clicks;
        for(let i=0; i<campaign_clicks.length; i++){
            campaign_clicks[i].date_unixtime_convert = getDateTimeZoneFromUnix(Number(campaign_clicks[i].date_unixtime), this.state.current_timezone);
        }
        this.setState({
            campaign_clicks:campaign_clicks
        })
    }

    Filter = () => {
        let table_data:any = [];
        let t = this.state.filter_search;
        let v = this.state.filter_fb_account;
        let b = this.state.filter_buyer;
        let headers = ['id', 'name', 'campaign_id', 'fb_account', 'fb_account_buyer'];
        
        for(let i=0; i<this.state.start_table_data.length; i++){
            table_data.push(this.state.start_table_data[i])
        }
        
        if(t != ''){
            for(let i=0; i<this.state.start_table_data.length; i++){
                let check:any = false;
                Object.keys(this.state.start_table_data[i]).forEach((item:any) => {
                    if(headers.indexOf(item) != -1){
                        if(String(this.state.start_table_data[i][item]).toLocaleLowerCase().indexOf(t.toLocaleLowerCase()) != -1){
                            check = true;
                        }
                    }
                });
                if(check == false){
                    //table_data.splice(i, 1);
                    table_data[i] = null;
                }
            }
        }
        if(v != null){
            for(let i=0; i<this.state.start_table_data.length; i++){
                if(String(v.value) != this.state.start_table_data[i].fb_account_id && table_data[i] != null){
                    // table_data.splice(i, 1);
                    table_data[i] = null;
                }
            }
        }
        if(b != null){
            for(let i=0; i<this.state.start_table_data.length; i++){
                if(String(b.value) != this.state.start_table_data[i].fb_account_buyer_id && table_data[i] != null){
                    // table_data.splice(i, 1);
                    table_data[i] = null;
                }
            }
        }

        let temp:any = [];
        for(let i=0; i<table_data.length; i++){
            if(table_data[i] != null){
                temp.push(table_data[i])
            }
        }

        this.setState({
            table_data:temp
        })
    }

    ResetFilter = () => {
        this.setState({
            table_data:this.state.start_table_data,
            filter_fb_account:null,
            filter_buyer:null,
            filter_search:''
        })
    }

    RenderEditCampaignForm = () => {
        return(
            <div>
                <ul className="nav nav-tabs nav-justified">
                    {this.state.edit_modal_tab.map((item:any) => {
                        return (
                            <li key={item.id} onClick={()=>{this.SetEditModalTab(item.id)}} className="nav-item c_p">
                                <span className={item.active?'nav-link active':'nav-link'}>{item.name}</span>
                            </li>
                        )
                    })}
                </ul>
                <div className="tab-content">
                    <div className={this.state.edit_modal_tab[0].active?'tab-pane container active':'tab-pane container fade'} >
                        <div style={{marginTop:10}}>
                            <div className='form-group'>
                                <label>fb account</label>
                                <FbAccountsSelect value={this.state.edit_campaign_fb_account} onChange={(t:any)=>{this.setState({edit_campaign_fb_account:t})}}></FbAccountsSelect>
                                <label>offer</label>
                                <OffersSelect value={this.state.edit_campaign_offer} isMulti={true} onChange={(t:any)=>{this.setState({edit_campaign_offer:t})}}></OffersSelect>
                                <label>partner</label>
                                <PartnersSelect value={this.state.edit_campaign_partner} isMulti={true} onChange={(t:any)=>{this.setState({edit_campaign_partner:t})}}></PartnersSelect>
                                <label>campaign_id</label>
                                <input onInput={(t)=>{this.setState({edit_campaign_campaign_id:t.currentTarget.value})}} value={this.state.edit_campaign_campaign_id} className='form-control' type='number'></input>
                                <label>status</label>
                                <Select value={this.state.edit_campaign_status} onChange={(t)=>{this.setState({edit_campaign_status:t})}} options={[{label:'true', value:true}, {label:'false', value:false}]}></Select>
                            </div>
                            <button onClick={()=>{this.EditCampaign()}} disabled={this.state.add_campaign_loader} className='btn btn-primary form-control'>{this.state.add_campaign_loader?getBtnLoader():null} edit campaign</button>
                            <hr style={st.hr}></hr>
                            <button onClick={()=>{this.DeleteCampaign()}} disabled={this.state.add_campaign_loader} className='btn btn-danger form-control'>{this.state.add_campaign_loader?getBtnLoader():null} delete campaign</button>
                        </div>
                    </div>
                    <div className={this.state.edit_modal_tab[1].active?'tab-pane container active':'tab-pane container fade'} style={{padding:0}}>
                        {this.state.campaign_expenses_loader?<PageLoader center={true}></PageLoader>:null}
                        {this.state.campaign_expenses_loader?null:
                            <div>
                                <div style={{display:'flex', justifyContent:'center', marginBottom:5, marginTop:5}}>
                                    <ul className="list-group list-group-horizontal">
                                        <li onClick={()=>{this.setState({current_timezone:this.state.edit_campaign_fb_account_timezone});setTimeout(()=>{this.ConvertUnixTimeWithTimezone('expenses')},200)}} className={this.state.current_timezone == this.state.edit_campaign_fb_account_timezone?'list-group-item active c_p':'list-group-item c_p'} style={{padding:6}}>{this.state.edit_campaign_fb_account_timezone}</li>
                                        <li onClick={()=>{this.setState({current_timezone:systemConfig.user_data.timezone});setTimeout(()=>{this.ConvertUnixTimeWithTimezone('expenses')},200)}} className={this.state.current_timezone == systemConfig.user_data.timezone?'list-group-item active c_p':'list-group-item c_p'} style={{padding:6}}>{systemConfig.user_data.timezone}</li>
                                    </ul>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <label>date</label>
                                        <input value={this.state.add_campaign_expenses_date.split(' ')[0]} onInput={(t)=>{this.setState({add_campaign_expenses_date:t.currentTarget.value+' 12:00'})}} className='form-control' type='date'></input>
                                    </div>
                                    <div className='col'>
                                        <label>expenses</label>
                                        <input value={this.state.add_campaign_expenses_count} onInput={(t)=>{this.setState({add_campaign_expenses_count:t.currentTarget.value})}} className='form-control' type='number'></input>
                                    </div>
                                    <div className='col'>
                                        <label>clicks</label>
                                        <input value={this.state.add_campaign_clicks_count} onInput={(t)=>{this.setState({add_campaign_clicks_count:t.currentTarget.value})}} className='form-control' type='number'></input>
                                    </div>
                                    <div className='col'>
                                        <label>add</label>
                                        <button onClick={()=>{this.AddCampaignExpenses()}} disabled={this.state.add_campaign_expenses_loader} className='form-control btn btn-primary'>{this.state.add_campaign_expenses_loader?getBtnLoader():null} add ({this.state.edit_campaign_currency})</button>
                                    </div>
                                </div>
                                <hr style={st.hr}></hr>
                                <div style={{marginTop:5, maxHeight:400, minHeight:400, overflowY:'scroll'}}>
                                    {this.state.campaign_expenses.map((item:any) => {
                                        return (
                                            <div key={item.id} className='card' style={{marginBottom:10}}>
                                                <div style={{padding:5}}>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <div style={{display:'flex', justifyContent:'center'}}>
                                                                <span style={{marginTop:6}}>{moment(item.date_unixtime_convert).format('DD.MM.YYYY')}</span>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <input value={Number(item.count).toFixed(2)} onInput={(t)=>{this.UpdateListCampaignExpenses(item.id, t.currentTarget.value)}} className='form-control' type='number'></input>
                                                        </div>
                                                        <div className='col'>
                                                            <input value={item.clicks_count} onInput={(t)=>{this.UpdateListCampaignExpenses(item.clicks_id, t.currentTarget.value, 'clicks')}} className='form-control' type='number'></input>
                                                        </div>
                                                        <div className='col'>
                                                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around', alignItems:'center', padding:6}}>
                                                                (USD)
                                                                {item.edit_loader?getBtnLoader():<i onClick={()=>{this.UpdateCampaignExpenses(item)}} className="material-icons offline_pin c_p text-primary">&#xe90a;</i>}
                                                                {item.edit_loader?getBtnLoader():<i onClick={()=>{this.DeleteCampaignExpenses(item)}} className="material-icons delete_forever c_p text-danger">&#xe92b;</i>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                    
                </div>
                
            </div>
            
        )
    }
  
    render() {
        return(
            <div style={st.card}>
                <div className='card-body'>
                    <div className='form-group'>
                        <button onClick={()=>{this.setState({render:'campaigns_list'});this.GetCampaignsList();}} style={{marginRight:5}} className={(this.state.render == 'campaigns_list')? 'btn btn-primary':'btn btn-outline-primary'}>campaigns list</button>
                        <button onClick={()=>{this.setState({render:'add_campaign'})}} style={{marginRight:5}} className={(this.state.render == 'add_campaign')? 'btn btn-primary':'btn btn-outline-primary'}>add campaign</button>
                    </div>
                    <hr style={st.hr}></hr>
                    {(this.state.render == 'campaigns_list')?
                    <div className='row'>
                        <div className='col'>
                            <label>Search</label>
                            <input value={this.state.filter_search} onInput={(t)=>{this.setState({filter_search:t.currentTarget.value}); setTimeout(()=>{this.Filter()},200);}} className='form-control'></input>
                        </div>
                        <div className='col'>
                            <label>Buyer</label>
                            <UsersSelect value={this.state.filter_buyer} onChange={(t:any)=>{this.setState({filter_buyer:t}); setTimeout(()=>{this.Filter()},200);}}></UsersSelect>
                        </div>
                        <div className='col'>
                            <label>FB account</label>
                            <FbAccountsSelect buyer={this.state.filter_buyer} value={this.state.filter_fb_account} onChange={(t:any)=>{this.setState({filter_fb_account:t}); setTimeout(()=>{this.Filter()},200);}}></FbAccountsSelect>
                        </div>
                        <div className='col'>
                            <label>Reset</label>
                            <button onClick={()=>{this.ResetFilter()}} className='btn btn-outline-danger form-control'>Reset</button>
                        </div>
                    </div>
                    :null}
                    
                    <br></br>
                    {(this.state.render == 'campaigns_list' || this.state.render == 'edit_campaign')?this.RenderTable():null}
                    {(this.state.render == 'add_campaign')?this.RenderAddCampaignForm():null}
                    
                    <CustomModal header={'Edit campaign ' + '('+this.state.edit_campaign_name+')'} size={'big'} visible={this.state.edit_modal} callback={()=>{this.setState({edit_modal:false,  edit_modal_tab:[{id:0, name:'campaign', active:true}, {id:1, name:'expenses', active:false}]})}}>{this.RenderEditCampaignForm()}</CustomModal>
                    {/* {(this.state.render == 'edit_campaign')?<><CustomModal header={'Modal'} size={'large'} visible={true}>{this.RenderEditCampaignForm()}</CustomModal></>:null} */}
                </div>
            </div>
        )
    }
}

