import React, { Component } from 'react';
import { IonContent } from '@ionic/react';
import { st } from '../components/css/st';
import PageLoader from '../components/PageLoader';
import ReportTable from '../components/ReportTable';
import { ApiReq, notySend, urlNavigate, getBtnLoader, generateToken, parsGetParams, getSystemConfig, getDateTimeZoneFromUnix } from '../components/function';
import OffersSelect from '../components/OffersSelect';
import UsersSelect from '../components/UsersSelect';
import FbAccountsSelect from '../components/FbAccountsSelect';
import PartnersSelect from '../components/PartnersSelect';
import CampaignsSelect from '../components/CampaignsSelect';
import OsSelect from '../components/OsSelect';
import moment from 'moment-timezone';

let systemConfig = getSystemConfig();

// let table_data = [
//     {date:'04.10.1998', lead:1, cost:10, revenue:20, profit:27, clicks:27, cr:27, cpc:27, epc:27, roi:27}
// ];

// for(let i=0; i<50; i++){
//     // let temp = table_data[0];
//     // temp.lead = Math.floor(Math.random() * 100);
//     // temp.cost = Math.floor(Math.random() * 100);
//     table_data.push({date:'04.10.'+Math.floor(Math.random() * 2023), lead:Math.floor(Math.random() * 100), cost:Math.floor(Math.random() * 100), revenue:Math.floor(Math.random() * 100), profit:Math.floor(Math.random() * 100), clicks:Math.floor(Math.random() * 100), cr:Math.floor(Math.random() * 100), cpc:Math.floor(Math.random() * 100), epc:Math.floor(Math.random() * 100), roi:Math.floor(Math.random() * 100)})
// }

export default class ReportsPage extends Component<any, any> {
    constructor(props: any){
        super(props);
        this.state = {
            render:'',
            tabs_summary:[
                {id:0, name:'Daily summary', rout:'daily_summary', req_param:'daily', active:true, load:false},
                {id:1, name:'Offer summary', rout:'offer_summary', req_param:'offer', active:false, load:false},
                {id:2, name:'Partner summary', rout:'partner_summary', req_param:'partner', active:false, load:false},
                {id:3, name:'Buyer summary', rout:'buyer_summary', req_param:'buyer', active:false, load:false},
            ],
            active_tab_summary:{id:0, name:'Daily summary', rout:'daily_summary', req_param:'daily', active:true, load:false},
            report_table_head:['Date', 'Lead', 'Cost', 'Revenue', 'Profit', 'Clicks', 'CR', 'CPC', 'EPC', 'ROI'],
            report_table_body:[],
            report_table_load:false,
            filter_date_from:'',
            filter_date_to:'',
            filter_offer:[],
            filter_account:[],
            filter_campaign:[],
            filter_partner:[],
            filter_buyer:[],
            filter_os:[],
            filter_timzone:'Native'
        }
    }
  
    componentDidMount(){
        //this.GetReport();

    }

    GetReport = () => {
        let date_from_unix = (this.state.filter_date_from == '')?null:(this.state.filter_timzone == 'Native')?moment.tz(this.state.filter_date_from, 'UTC').unix():moment.tz(this.state.filter_date_from, systemConfig.user_data.timezone).unix();
        let date_to_unix = (this.state.filter_date_to == '')?null:(this.state.filter_timzone == 'Native')?moment.tz(this.state.filter_date_to, 'UTC').unix():moment.tz(this.state.filter_date_to, systemConfig.user_data.timezone).unix();
        this.setState({
            report_table_load:true,
        })
        //console.log({type_report:this.state.active_tab_summary.req_param, timezone:this.state.filter_timzone, date_from_unix:date_from_unix, date_to_unix:date_to_unix, date_from:this.state.filter_date_from, date_to:this.state.filter_date_to, fb_account:this.state.filter_account, offer:this.state.filter_offer, campaign:this.state.filter_campaign, buyer:this.state.filter_buyer, partner:this.state.filter_partner, os:this.state.filter_os})
        ApiReq('/reports/get_report/', {type_report:this.state.active_tab_summary.req_param, timezone:this.state.filter_timzone, date_from_unix:date_from_unix, date_to_unix:date_to_unix, date_from:this.state.filter_date_from, date_to:this.state.filter_date_to, fb_account:this.state.filter_account, offer:this.state.filter_offer, campaign:this.state.filter_campaign, buyer:this.state.filter_buyer, partner:this.state.filter_partner, os:this.state.filter_os}).then(res => {
            if(res != false){
                if(res.success == true){
                    //console.log(res);
                    if(this.state.active_tab_summary.req_param == 'daily'){
                        let data = res.data;
                        data.sort((a:any,b:any) => a.date_unixtime - b.date_unixtime)
                        for(let i = 0; i < data.length; i++){
                            data[i].date = moment(data[i].date).format('DD.MM.YYYY')
                        }
                        
                        this.setState({
                            report_table_head:['Date', 'Lead', 'Cost', 'Revenue', 'Profit', 'Clicks', 'CR', 'CPC', 'EPC', 'CPL', 'ROI'],
                            report_table_body:data,
                            report_table_load:false,
                        })
                    }
                    if(this.state.active_tab_summary.req_param == 'offer'){
                        this.setState({
                            report_table_head:['Offer', 'Lead', 'Cost', 'Revenue', 'Profit', 'Clicks', 'CR', 'CPC', 'EPC', 'CPL', 'ROI'],
                            report_table_body:res.data,
                            report_table_load:false,
                        })
                    }
                    if(this.state.active_tab_summary.req_param == 'partner'){
                        this.setState({
                            report_table_head:['Partner', 'Lead', 'Cost', 'Revenue', 'Profit', 'Clicks', 'CR', 'CPC', 'EPC', 'CPL', 'ROI'],
                            report_table_body:res.data,
                            report_table_load:false,
                        })
                    }
                    if(this.state.active_tab_summary.req_param == 'buyer'){
                        this.setState({
                            report_table_head:['Buyer', 'Lead', 'Cost', 'Revenue', 'Profit', 'Clicks', 'CR', 'CPC', 'EPC', 'CPL', 'ROI'],
                            report_table_body:res.data,
                            report_table_load:false,
                        })
                    }
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info)
                }
            }
        })
    }
    
    SetTab = (id:number) => {
        let tabs_summary = this.state.tabs_summary;
        for(let i=0; i<tabs_summary.length; i++){
            tabs_summary[i].active = false;
            tabs_summary[i].load = false;
        }
        tabs_summary[id].active = true;
        this.setState({tabs_summary: tabs_summary, active_tab_summary:tabs_summary[id]})
        setTimeout(()=>{
            //this.GetReport();
        },100)
    }

    Filter = () => {
        this.GetReport();
    }

    ResetFilter = () => {
        this.setState({
            filter_date_from:'',
            filter_date_to:'',
            filter_offer:[],
            filter_account:[],
            filter_campaign:[],
            filter_partner:[],
            filter_buyer:[],
            filter_os:[],
            filter_timzone:'Native'
        })
    }
  
    render() {
        return(
            <div style={st.card}>
                <div className='card-body'>
                    <div>
                        {this.state.tabs_summary.map((item:any) => {
                            if(item.id == 3){
                                if(systemConfig.user_data.role != 'buyer'){
                                    return (
                                        <button key={item.id} disabled={this.state.report_table_load?true:false} onClick={()=>{this.SetTab(item.id)}} style={{margin:5}} className={item.active?'btn btn-primary':'btn btn-outline-primary'}>{item.load?getBtnLoader():null} {item.name}</button>
                                    )
                                }
                                else{
                                    return null;
                                }
                            }
                            else{
                                return (
                                    <button key={item.id} disabled={this.state.report_table_load?true:false} onClick={()=>{this.SetTab(item.id)}} style={{margin:5}} className={item.active?'btn btn-primary':'btn btn-outline-primary'}>{item.load?getBtnLoader():null} {item.name}</button>
                                )
                            }
                            
                        })}
                    </div>
                    <hr style={st.hr}></hr>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <ul className="list-group list-group-horizontal">
                            <li onClick={()=>{this.setState({filter_timzone:'Native'})}} className={this.state.filter_timzone == 'Native'?'list-group-item active c_p':'list-group-item c_p'} style={{padding:6}}>Native</li>
                            <li onClick={()=>{this.setState({filter_timzone:systemConfig.user_data.timezone})}} className={this.state.filter_timzone == systemConfig.user_data.timezone?'list-group-item active c_p':'list-group-item c_p'} style={{padding:6}}>{systemConfig.user_data.timezone}</li>
                        </ul>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label>Date from</label>
                            <input  value={this.state.filter_date_from.split(' ')[0]} onInput={(t)=>{this.setState({filter_date_from:t.currentTarget.value+' 00:00'})}} type='date' className='form-control'></input>
                        </div>
                        <div className='col'>
                            <label>Date to</label>
                            <input  value={this.state.filter_date_to.split(' ')[0]} onInput={(t)=>{this.setState({filter_date_to:t.currentTarget.value+' 23:59'})}} type='date' className='form-control'></input>
                        </div>
                        <div className='col'>
                            <label>Offer</label>
                            <OffersSelect dynamicFilter={{params:['id'], data:[this.state.filter_campaign]}} value={this.state.filter_offer} onChange={(t:any)=>{this.setState({filter_offer:t})}} isMulti={true}></OffersSelect>
                        </div>
                        {/* <div className='col'>
                            <label>Account</label>
                            <FbAccountsSelect value={this.state.filter_account} onChange={(t:any)=>{this.setState({filter_account:t})}} isMulti={true}></FbAccountsSelect>
                        </div> */}
                        <div className='col'>
                            <label>OS</label>
                            <OsSelect isMulti={true} value={this.state.filter_os} onChange={(t:any)=>{this.setState({filter_os:t})}}></OsSelect>
                        </div>
                    </div>
                    <div>
                        <label>Account</label>
                        <FbAccountsSelect buyer={this.state.filter_buyer} value={this.state.filter_account} onChange={(t:any)=>{this.setState({filter_account:t})}} isMulti={true}></FbAccountsSelect>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label>Campaign</label>
                            <CampaignsSelect dynamicFilter={{params:['fb_account_id'], data:[this.state.filter_account]}} value={this.state.filter_campaign} onChange={(t:any)=>{this.setState({filter_campaign:t})}} isMulti={true}></CampaignsSelect>
                        </div>
                        <div className='col'>
                            <label>Partner</label>
                            <PartnersSelect dynamicFilter={{params:['id'], data:[this.state.filter_campaign]}} value={this.state.filter_partner} onChange={(t:any)=>{this.setState({filter_partner:t})}} isMulti={true}></PartnersSelect>
                        </div>
                        <div className='col'>
                            <label>Buyer</label>
                            <UsersSelect dynamicFilter={{params:['id'], data:[this.state.filter_campaign]}} value={this.state.filter_buyer} onChange={(t:any)=>{this.setState({filter_buyer:t})}} isMulti={true}></UsersSelect>
                        </div>
                        <div className="col">
                            <label>Reset/Filter</label>
                            <div className='row'>
                                <div className='col'>
                                    <div className="btn-group" style={{width:'100%'}}>
                                        <button onClick={()=>{this.ResetFilter()}} className="btn btn-outline-danger "> Reset</button>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className="btn-group" style={{width:'100%'}}>
                                        <button disabled={this.state.report_table_load?true:false} onClick={()=>{this.Filter()}} className="btn btn-outline-primary ">{this.state.report_table_load?getBtnLoader():null} Filter</button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                    <hr style={st.hr}></hr>
                    {this.state.report_table_load?<div className='text-center'><br></br><PageLoader></PageLoader></div>:<ReportTable thead={this.state.report_table_head} tbody={this.state.report_table_body}></ReportTable>}
                </div>
            </div>
        )
    }
}

