import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate } from '../components/function';
import Select from 'react-select'


export default class OsSelect extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        select_data:[
          {
            "label": "Android",
            "value": "Android"
          },
          {
            "label": "iOS",
            "value": "iOS"
          },
          {
            "label": "BlackBerry OS",
            "value": "BlackBerry OS"
          },
          {
            "label": "iPadOS",
            "value": "iPadOS"
          },
          {
            "label": "OS X",
            "value": "OS X"
          },
          {
            "label": "Windows",
            "value": "Windows"
          },
          {
            "label": "Windows Phone",
            "value": "Windows Phone"
          },
          {
            "label": "Android TV",
            "value": "Android TV"
          },
          {
            "label": "Tizen",
            "value": "Tizen"
          },
          {
            "label": "Ubuntu",
            "value": "Ubuntu"
          },
          {
            "label": "Symbian",
            "value": "Symbian"
          },
          {
            "label": "Symbian OS",
            "value": "Symbian OS"
          },
          {
            "label": "Solaris",
            "value": "Solaris"
          },
          {
            "label": "Fedora",
            "value": "Fedora"
          },
          {
            "label": "GNU/Linux",
            "value": "GNU/Linux"
          },
          {
            "label": "HarmonyOS",
            "value": "HarmonyOS"
          },
          {
            "label": "Java ME",
            "value": "Java ME"
          },
          {
            "label": "KiaOS",
            "value": "KiaOS"
          },
          {
            "label": "OpenBSD",
            "value": "OpenBSD"
          },
          {
            "label": "ClearOS Mobile",
            "value": "ClearOS Mobile"
          },
          {
            "label": "Chrome OS",
            "value": "Chrome OS"
          },
          {
            "label": "CentOS",
            "value": "CentOS"
          },
          
        ],
          currentValue:null
      }
      
    }
  
    componentDidMount(){
      if(this.props.value != null){
        for(let i=0; i<this.state.select_data.length; i++){
            if(this.state.select_data[i].value == this.props.value){
                this.setState({
                    currentValue:this.state.select_data[i]
                })
            }
        }
      }
    }

    Change = (res:any) => {
        if(this.props.onChange != null){
            this.props.onChange(res);
            this.setState({
              currentValue:res
            })
        }
    }
  
    
  
    render() {
        return(
            <Select value={this.props.value} isMulti={this.props.isMulti} onChange={(res)=>{this.Change(res)}}   options={this.state.select_data} />
        )
    }
}

