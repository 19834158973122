import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate } from '../components/function';
import Select from 'react-select';



export default class UsersSelect extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        req_data:[],
        select_data:[],
        loading:true,
        value:null,
      }
    }
  
    componentDidMount(){
        this.GetUsersList();
        
    }

    GetUsersList = () => {
        ApiReq('/users/get_users/').then(res => {
            if(res != false){
              if(res.success == true){
                let selectData:any = [];
                for(let i=0; i<res.data.length; i++){
                    let check_role = true;
                    let check_created_by_user = true;


                    if(this.props.role != null){
                        if(this.props.role != res.data[i].role){
                            check_role = false;
                        }
                    }
                    if(this.props.created_by_user != null){
                        if(this.props.created_by_user != res.data[i].created_by_user){
                            check_created_by_user = false;
                        }
                    }

                    if(check_role == true && check_created_by_user == true){
                        selectData.push({label:res.data[i].login, value:res.data[i].id});
                    }
                }
                this.setState({
                    req_data:res.data,
                    select_data:selectData,
                    loading:false
                })
                this.CheckValue();
              }
              else{
                notySend('error', 'error #'+res.error+'\n'+res.info)
              }
            }
        })
    }

    Change = (res:any) => {
        if(this.props.onChange != null){
            this.props.onChange(res);
        }
        this.setState({
          value:res
        })
    }

    CheckValue = () => {
      // if(this.props.value != null){
      //     for(let i=0; i<this.state.select_data.length; i++){
      //         if(this.state.select_data[i].label == this.props.value){
      //             this.setState({
      //               value:this.state.select_data[i]
      //             });
      //             console.log(this.state.select_data[i])
      //             break;
                  
      //         }
      //     }
      //     this.setState({
      //       value:this.props.value
      //     });
      // }
      // else{
      //     this.setState({
      //       value:null
      //     });
      // }
    }

    GetValues = (array:any) => {
      let newArray:any = [];
      for(let i=0; i<array.length; i++){
          newArray.push(array[i].data.fb_account_buyer_id);
      }
      return newArray;
    }

    DynamicFilter = () => {
      let dynamicFilter = this.props.dynamicFilter;
      if(dynamicFilter != null){
          this.setState({loading:false, select_data:[]})
          let selectData:any = [];
          if(dynamicFilter.data[0].length > 0){
              for(let i=0; i<this.state.req_data.length; i++){
                  let check = false;
                  for(let j=0; j<dynamicFilter.data.length; j++){
                      let temp = this.GetValues(dynamicFilter.data[j]);
                      if(temp.length > 0){
                          for(let k=0; k<temp.length; k++){
                              if(String(this.state.req_data[i][dynamicFilter.params[j]]) == String(temp[k])){
                                  check = true;
                                  break;
                              }
                          }
                      }
                      if(check == true){
                          selectData.push({label:this.state.req_data[i].login, value:this.state.req_data[i].id});
                      }
                  }
              }
          }
          else{
              for(let i=0; i<this.state.req_data.length; i++){
                  selectData.push({label:this.state.req_data[i].login, value:this.state.req_data[i].id});
              }
          }
          
          this.setState({
              select_data:selectData,
              loading:false
          })
      }
    }
  
    render() {
      if(this.props.isMulti == true){
          return (
              <Select  onFocus={()=>{this.DynamicFilter()}} value={this.props.value} isLoading={this.state.loading} isMulti={true} onChange={(res)=>{this.Change(res)}}  options={this.state.select_data} />
          )
      }
      else{
          return (
              <Select value={this.props.value} isLoading={this.state.loading}  onChange={(res)=>{this.Change(res)}}  options={this.state.select_data} />
          )
      }
    }

   
}

