import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate } from '../components/function';
import Select from 'react-select'

//source url https://github.com/dmfilipenko/timezones.json/blob/master/timezones.json

export default class TimeZonesSelect extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        select_data:[
            {
              "label": "(UTC-12:00) International Date Line West (Etc/GMT+12)",
              "value": "Etc/GMT+12"
            },
            {
              "label": "(UTC-11:00) Coordinated Universal Time-11 (Etc/GMT+11)",
              "value": "Etc/GMT+11"
            },
            {
              "label": "(UTC-11:00) Coordinated Universal Time-11 (Pacific/Midway)",
              "value": "Pacific/Midway"
            },
            {
              "label": "(UTC-11:00) Coordinated Universal Time-11 (Pacific/Niue)",
              "value": "Pacific/Niue"
            },
            {
              "label": "(UTC-11:00) Coordinated Universal Time-11 (Pacific/Pago_Pago)",
              "value": "Pacific/Pago_Pago"
            },
            {
              "label": "(UTC-10:00) Hawaii (Etc/GMT+10)",
              "value": "Etc/GMT+10"
            },
            {
              "label": "(UTC-10:00) Hawaii (Pacific/Honolulu)",
              "value": "Pacific/Honolulu"
            },
            {
              "label": "(UTC-10:00) Hawaii (Pacific/Rarotonga)",
              "value": "Pacific/Rarotonga"
            },
            {
              "label": "(UTC-10:00) Hawaii (Pacific/Tahiti)",
              "value": "Pacific/Tahiti"
            },
            {
              "label": "(UTC-09:00) Alaska (America/Anchorage)",
              "value": "America/Anchorage"
            },
            {
              "label": "(UTC-09:00) Alaska (America/Juneau)",
              "value": "America/Juneau"
            },
            {
              "label": "(UTC-09:00) Alaska (America/Nome)",
              "value": "America/Nome"
            },
            {
              "label": "(UTC-09:00) Alaska (America/Sitka)",
              "value": "America/Sitka"
            },
            {
              "label": "(UTC-09:00) Alaska (America/Yakutat)",
              "value": "America/Yakutat"
            },
            {
              "label": "(UTC-08:00) Pacific Standard Time (US & Canada) (America/Los_Angeles)",
              "value": "America/Los_Angeles"
            },
            {
              "label": "(UTC-08:00) Pacific Standard Time (US & Canada) (America/Tijuana)",
              "value": "America/Tijuana"
            },
            {
              "label": "(UTC-08:00) Pacific Standard Time (US & Canada) (America/Vancouver)",
              "value": "America/Vancouver"
            },
            {
              "label": "(UTC-07:00) Arizona (America/Creston)",
              "value": "America/Creston"
            },
            {
              "label": "(UTC-07:00) Arizona (America/Dawson)",
              "value": "America/Dawson"
            },
            {
              "label": "(UTC-07:00) Arizona (America/Dawson_Creek)",
              "value": "America/Dawson_Creek"
            },
            {
              "label": "(UTC-07:00) Arizona (America/Hermosillo)",
              "value": "America/Hermosillo"
            },
            {
              "label": "(UTC-07:00) Arizona (America/Phoenix)",
              "value": "America/Phoenix"
            },
            {
              "label": "(UTC-07:00) Arizona (America/Whitehorse)",
              "value": "America/Whitehorse"
            },
            {
              "label": "(UTC-07:00) Arizona (Etc/GMT+7)",
              "value": "Etc/GMT+7"
            },
            {
              "label": "(UTC-06:00) Chihuahua, La Paz, Mazatlan (America/Chihuahua)",
              "value": "America/Chihuahua"
            },
            {
              "label": "(UTC-07:00) Chihuahua, La Paz, Mazatlan (America/Mazatlan)",
              "value": "America/Mazatlan"
            },
            {
              "label": "(UTC-07:00) Mountain Time (US & Canada) (America/Boise)",
              "value": "America/Boise"
            },
            {
              "label": "(UTC-07:00) Mountain Time (US & Canada) (America/Cambridge_Bay)",
              "value": "America/Cambridge_Bay"
            },
            {
              "label": "(UTC-07:00) Mountain Time (US & Canada) (America/Denver)",
              "value": "America/Denver"
            },
            {
              "label": "(UTC-07:00) Mountain Time (US & Canada) (America/Edmonton)",
              "value": "America/Edmonton"
            },
            {
              "label": "(UTC-07:00) Mountain Time (US & Canada) (America/Inuvik)",
              "value": "America/Inuvik"
            },
            {
              "label": "(UTC-06:00) Mountain Time (US & Canada) (America/Ojinaga)",
              "value": "America/Ojinaga"
            },
            {
              "label": "(UTC-07:00) Mountain Time (US & Canada) (America/Yellowknife)",
              "value": "America/Yellowknife"
            },
            {
              "label": "(UTC-06:00) Central America (America/Belize)",
              "value": "America/Belize"
            },
            {
              "label": "(UTC-06:00) Central America (America/Costa_Rica)",
              "value": "America/Costa_Rica"
            },
            {
              "label": "(UTC-06:00) Central America (America/El_Salvador)",
              "value": "America/El_Salvador"
            },
            {
              "label": "(UTC-06:00) Central America (America/Guatemala)",
              "value": "America/Guatemala"
            },
            {
              "label": "(UTC-06:00) Central America (America/Managua)",
              "value": "America/Managua"
            },
            {
              "label": "(UTC-06:00) Central America (America/Tegucigalpa)",
              "value": "America/Tegucigalpa"
            },
            {
              "label": "(UTC-06:00) Central America (Etc/GMT+6)",
              "value": "Etc/GMT+6"
            },
            {
              "label": "(UTC-06:00) Central America (Pacific/Galapagos)",
              "value": "Pacific/Galapagos"
            },
            {
              "label": "(UTC-06:00) Central Time (US & Canada) (America/Chicago)",
              "value": "America/Chicago"
            },
            {
              "label": "(UTC-06:00) Central Time (US & Canada) (America/Indiana/Knox)",
              "value": "America/Indiana/Knox"
            },
            {
              "label": "(UTC-06:00) Central Time (US & Canada) (America/Indiana/Tell_City)",
              "value": "America/Indiana/Tell_City"
            },
            {
              "label": "(UTC-06:00) Central Time (US & Canada) (America/Matamoros)",
              "value": "America/Matamoros"
            },
            {
              "label": "(UTC-06:00) Central Time (US & Canada) (America/Menominee)",
              "value": "America/Menominee"
            },
            {
              "label": "(UTC-06:00) Central Time (US & Canada) (America/North_Dakota/Beulah)",
              "value": "America/North_Dakota/Beulah"
            },
            {
              "label": "(UTC-06:00) Central Time (US & Canada) (America/North_Dakota/Center)",
              "value": "America/North_Dakota/Center"
            },
            {
              "label": "(UTC-06:00) Central Time (US & Canada) (America/North_Dakota/New_Salem)",
              "value": "America/North_Dakota/New_Salem"
            },
            {
              "label": "(UTC-06:00) Central Time (US & Canada) (America/Rainy_River)",
              "value": "America/Rainy_River"
            },
            {
              "label": "(UTC-06:00) Central Time (US & Canada) (America/Rankin_Inlet)",
              "value": "America/Rankin_Inlet"
            },
            {
              "label": "(UTC-06:00) Central Time (US & Canada) (America/Resolute)",
              "value": "America/Resolute"
            },
            {
              "label": "(UTC-06:00) Central Time (US & Canada) (America/Winnipeg)",
              "value": "America/Winnipeg"
            },
            {
              "label": "(UTC-06:00) Guadalajara, Mexico City, Monterrey (America/Bahia_Banderas)",
              "value": "America/Bahia_Banderas"
            },
            {
              "label": "(UTC-05:00) Guadalajara, Mexico City, Monterrey (America/Cancun)",
              "value": "America/Cancun"
            },
            {
              "label": "(UTC-06:00) Guadalajara, Mexico City, Monterrey (America/Merida)",
              "value": "America/Merida"
            },
            {
              "label": "(UTC-06:00) Guadalajara, Mexico City, Monterrey (America/Mexico_City)",
              "value": "America/Mexico_City"
            },
            {
              "label": "(UTC-06:00) Guadalajara, Mexico City, Monterrey (America/Monterrey)",
              "value": "America/Monterrey"
            },
            {
              "label": "(UTC-06:00) Saskatchewan (America/Regina)",
              "value": "America/Regina"
            },
            {
              "label": "(UTC-06:00) Saskatchewan (America/Swift_Current)",
              "value": "America/Swift_Current"
            },
            {
              "label": "(UTC-05:00) Bogota, Lima, Quito (America/Bogota)",
              "value": "America/Bogota"
            },
            {
              "label": "(UTC-05:00) Bogota, Lima, Quito (America/Cayman)",
              "value": "America/Cayman"
            },
            {
              "label": "(UTC-05:00) Bogota, Lima, Quito (America/Eirunepe)",
              "value": "America/Eirunepe"
            },
            {
              "label": "(UTC-05:00) Bogota, Lima, Quito (America/Guayaquil)",
              "value": "America/Guayaquil"
            },
            {
              "label": "(UTC-05:00) Bogota, Lima, Quito (America/Jamaica)",
              "value": "America/Jamaica"
            },
            {
              "label": "(UTC-05:00) Bogota, Lima, Quito (America/Lima)",
              "value": "America/Lima"
            },
            {
              "label": "(UTC-05:00) Bogota, Lima, Quito (America/Panama)",
              "value": "America/Panama"
            },
            {
              "label": "(UTC-05:00) Bogota, Lima, Quito (America/Rio_Branco)",
              "value": "America/Rio_Branco"
            },
            {
              "label": "(UTC-05:00) Bogota, Lima, Quito (Etc/GMT+5)",
              "value": "Etc/GMT+5"
            },
            {
              "label": "(UTC-05:00) Eastern Time (US & Canada) (America/Detroit)",
              "value": "America/Detroit"
            },
            {
              "label": "(UTC-05:00) Eastern Time (US & Canada) (America/Havana)",
              "value": "America/Havana"
            },
            {
              "label": "(UTC-05:00) Eastern Time (US & Canada) (America/Indiana/Petersburg)",
              "value": "America/Indiana/Petersburg"
            },
            {
              "label": "(UTC-05:00) Eastern Time (US & Canada) (America/Indiana/Vincennes)",
              "value": "America/Indiana/Vincennes"
            },
            {
              "label": "(UTC-05:00) Eastern Time (US & Canada) (America/Indiana/Winamac)",
              "value": "America/Indiana/Winamac"
            },
            {
              "label": "(UTC-05:00) Eastern Time (US & Canada) (America/Iqaluit)",
              "value": "America/Iqaluit"
            },
            {
              "label": "(UTC-05:00) Eastern Time (US & Canada) (America/Kentucky/Monticello)",
              "value": "America/Kentucky/Monticello"
            },
            {
              "label": "(UTC-05:00) Eastern Time (US & Canada) (America/Louisville)",
              "value": "America/Louisville"
            },
            {
              "label": "(UTC-05:00) Eastern Time (US & Canada) (America/Nassau)",
              "value": "America/Nassau"
            },
            {
              "label": "(UTC-05:00) Eastern Time (US & Canada) (America/New_York)",
              "value": "America/New_York"
            },
            {
              "label": "(UTC-05:00) Eastern Time (US & Canada) (America/Nipigon)",
              "value": "America/Nipigon"
            },
            {
              "label": "(UTC-05:00) Eastern Time (US & Canada) (America/Pangnirtung)",
              "value": "America/Pangnirtung"
            },
            {
              "label": "(UTC-05:00) Eastern Time (US & Canada) (America/Port-au-Prince)",
              "value": "America/Port-au-Prince"
            },
            {
              "label": "(UTC-05:00) Eastern Time (US & Canada) (America/Thunder_Bay)",
              "value": "America/Thunder_Bay"
            },
            {
              "label": "(UTC-05:00) Eastern Time (US & Canada) (America/Toronto)",
              "value": "America/Toronto"
            },
            {
              "label": "(UTC-04:00) Eastern Daylight Time (US & Canada) (America/Pangnirtung)",
              "value": "America/Pangnirtung"
            },
            {
              "label": "(UTC-05:00) Indiana (East) (America/Indiana/Marengo)",
              "value": "America/Indiana/Marengo"
            },
            {
              "label": "(UTC-05:00) Indiana (East) (America/Indiana/Vevay)",
              "value": "America/Indiana/Vevay"
            },
            {
              "label": "(UTC-05:00) Indiana (East) (America/Indianapolis)",
              "value": "America/Indianapolis"
            },
            {
              "label": "(UTC-04:00) Caracas (America/Caracas)",
              "value": "America/Caracas"
            },
            {
              "label": "(UTC-04:00) Asuncion (America/Asuncion)",
              "value": "America/Asuncion"
            },
            {
              "label": "(UTC-04:00) Atlantic Time (Canada) (America/Glace_Bay)",
              "value": "America/Glace_Bay"
            },
            {
              "label": "(UTC-04:00) Atlantic Time (Canada) (America/Goose_Bay)",
              "value": "America/Goose_Bay"
            },
            {
              "label": "(UTC-04:00) Atlantic Time (Canada) (America/Halifax)",
              "value": "America/Halifax"
            },
            {
              "label": "(UTC-04:00) Atlantic Time (Canada) (America/Moncton)",
              "value": "America/Moncton"
            },
            {
              "label": "(UTC-04:00) Atlantic Time (Canada) (America/Thule)",
              "value": "America/Thule"
            },
            {
              "label": "(UTC-04:00) Atlantic Time (Canada) (Atlantic/Bermuda)",
              "value": "Atlantic/Bermuda"
            },
            {
              "label": "(UTC-04:00) Cuiaba (America/Campo_Grande)",
              "value": "America/Campo_Grande"
            },
            {
              "label": "(UTC-04:00) Cuiaba (America/Cuiaba)",
              "value": "America/Cuiaba"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Anguilla)",
              "value": "America/Anguilla"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Antigua)",
              "value": "America/Antigua"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Aruba)",
              "value": "America/Aruba"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Barbados)",
              "value": "America/Barbados"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Blanc-Sablon)",
              "value": "America/Blanc-Sablon"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Boa_Vista)",
              "value": "America/Boa_Vista"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Curacao)",
              "value": "America/Curacao"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Dominica)",
              "value": "America/Dominica"
            },
            {
              "label": "(UTC-05:00) Georgetown, La Paz, Manaus, San Juan (America/Grand_Turk)",
              "value": "America/Grand_Turk"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Grenada)",
              "value": "America/Grenada"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Guadeloupe)",
              "value": "America/Guadeloupe"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Guyana)",
              "value": "America/Guyana"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Kralendijk)",
              "value": "America/Kralendijk"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/La_Paz)",
              "value": "America/La_Paz"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Lower_Princes)",
              "value": "America/Lower_Princes"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Manaus)",
              "value": "America/Manaus"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Marigot)",
              "value": "America/Marigot"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Martinique)",
              "value": "America/Martinique"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Montserrat)",
              "value": "America/Montserrat"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Port_of_Spain)",
              "value": "America/Port_of_Spain"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Porto_Velho)",
              "value": "America/Porto_Velho"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Puerto_Rico)",
              "value": "America/Puerto_Rico"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Santo_Domingo)",
              "value": "America/Santo_Domingo"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/St_Barthelemy)",
              "value": "America/St_Barthelemy"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/St_Kitts)",
              "value": "America/St_Kitts"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/St_Lucia)",
              "value": "America/St_Lucia"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/St_Thomas)",
              "value": "America/St_Thomas"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/St_Vincent)",
              "value": "America/St_Vincent"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/Tortola)",
              "value": "America/Tortola"
            },
            {
              "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (Etc/GMT+4)",
              "value": "Etc/GMT+4"
            },
            {
              "label": "(UTC-03:00) Santiago (America/Santiago)",
              "value": "America/Santiago"
            },
            {
              "label": "(UTC-03:00) Santiago (Antarctica/Palmer)",
              "value": "Antarctica/Palmer"
            },
            {
              "label": "(UTC-03:30) Newfoundland (America/St_Johns)",
              "value": "America/St_Johns"
            },
            {
              "label": "(UTC-03:00) Brasilia (America/Sao_Paulo)",
              "value": "America/Sao_Paulo"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Argentina/Buenos_Aires)",
              "value": "America/Argentina/Buenos_Aires"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Argentina/Catamarca)",
              "value": "America/Argentina/Catamarca"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Argentina/Cordoba)",
              "value": "America/Argentina/Cordoba"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Argentina/Jujuy)",
              "value": "America/Argentina/Jujuy"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Argentina/La_Rioja)",
              "value": "America/Argentina/La_Rioja"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Argentina/Mendoza)",
              "value": "America/Argentina/Mendoza"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Argentina/Rio_Gallegos)",
              "value": "America/Argentina/Rio_Gallegos"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Argentina/Salta)",
              "value": "America/Argentina/Salta"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Argentina/San_Juan)",
              "value": "America/Argentina/San_Juan"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Argentina/San_Luis)",
              "value": "America/Argentina/San_Luis"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Argentina/Tucuman)",
              "value": "America/Argentina/Tucuman"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Argentina/Ushuaia)",
              "value": "America/Argentina/Ushuaia"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Buenos_Aires)",
              "value": "America/Buenos_Aires"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Catamarca)",
              "value": "America/Catamarca"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Cordoba)",
              "value": "America/Cordoba"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Jujuy)",
              "value": "America/Jujuy"
            },
            {
              "label": "(UTC-03:00) Buenos Aires (America/Mendoza)",
              "value": "America/Mendoza"
            },
            {
              "label": "(UTC-03:00) Cayenne, Fortaleza (America/Araguaina)",
              "value": "America/Araguaina"
            },
            {
              "label": "(UTC-03:00) Cayenne, Fortaleza (America/Belem)",
              "value": "America/Belem"
            },
            {
              "label": "(UTC-03:00) Cayenne, Fortaleza (America/Cayenne)",
              "value": "America/Cayenne"
            },
            {
              "label": "(UTC-03:00) Cayenne, Fortaleza (America/Fortaleza)",
              "value": "America/Fortaleza"
            },
            {
              "label": "(UTC-03:00) Cayenne, Fortaleza (America/Maceio)",
              "value": "America/Maceio"
            },
            {
              "label": "(UTC-03:00) Cayenne, Fortaleza (America/Paramaribo)",
              "value": "America/Paramaribo"
            },
            {
              "label": "(UTC-03:00) Cayenne, Fortaleza (America/Recife)",
              "value": "America/Recife"
            },
            {
              "label": "(UTC-03:00) Cayenne, Fortaleza (America/Santarem)",
              "value": "America/Santarem"
            },
            {
              "label": "(UTC-03:00) Cayenne, Fortaleza (Antarctica/Rothera)",
              "value": "Antarctica/Rothera"
            },
            {
              "label": "(UTC-03:00) Cayenne, Fortaleza (Atlantic/Stanley)",
              "value": "Atlantic/Stanley"
            },
            {
              "label": "(UTC-03:00) Cayenne, Fortaleza (Etc/GMT+3)",
              "value": "Etc/GMT+3"
            },
            {
              "label": "(UTC-03:00) Greenland (America/Godthab)",
              "value": "America/Godthab"
            },
            {
              "label": "(UTC-03:00) Montevideo (America/Montevideo)",
              "value": "America/Montevideo"
            },
            {
              "label": "(UTC-03:00) Salvador (America/Bahia)",
              "value": "America/Bahia"
            },
            {
              "label": "(UTC-02:00) Coordinated Universal Time-02 (America/Noronha)",
              "value": "America/Noronha"
            },
            {
              "label": "(UTC-02:00) Coordinated Universal Time-02 (Atlantic/South_Georgia)",
              "value": "Atlantic/South_Georgia"
            },
            {
              "label": "(UTC-02:00) Coordinated Universal Time-02 (Etc/GMT+2)",
              "value": "Etc/GMT+2"
            },
            {
              "label": "(UTC-01:00) Azores (America/Scoresbysund)",
              "value": "America/Scoresbysund"
            },
            {
              "label": "(UTC-01:00) Azores (Atlantic/Azores)",
              "value": "Atlantic/Azores"
            },
            {
              "label": "(UTC-01:00) Cape Verde Is. (Atlantic/Cape_Verde)",
              "value": "Atlantic/Cape_Verde"
            },
            {
              "label": "(UTC-01:00) Cape Verde Is. (Etc/GMT+1)",
              "value": "Etc/GMT+1"
            },
            {
              "label": "(UTC+01:00) Casablanca (Africa/Casablanca)",
              "value": "Africa/Casablanca"
            },
            {
              "label": "(UTC) Casablanca (Africa/El_Aaiun)",
              "value": "Africa/El_Aaiun"
            },
            {
              "label": "(UTC) Coordinated Universal Time (America/Danmarkshavn)",
              "value": "America/Danmarkshavn"
            },
            {
              "label": "(UTC) Coordinated Universal Time (Etc/GMT)",
              "value": "Etc/GMT"
            },
            {
              "label": "(UTC) Edinburgh, London (Europe/Isle_of_Man)",
              "value": "Europe/Isle_of_Man"
            },
            {
              "label": "(UTC) Edinburgh, London (Europe/Guernsey)",
              "value": "Europe/Guernsey"
            },
            {
              "label": "(UTC) Edinburgh, London (Europe/Jersey)",
              "value": "Europe/Jersey"
            },
            {
              "label": "(UTC) Edinburgh, London (Europe/London)",
              "value": "Europe/London"
            },
            {
              "label": "(UTC) Dublin, Lisbon (Atlantic/Canary)",
              "value": "Atlantic/Canary"
            },
            {
              "label": "(UTC) Dublin, Lisbon (Atlantic/Faeroe)",
              "value": "Atlantic/Faeroe"
            },
            {
              "label": "(UTC) Dublin, Lisbon (Atlantic/Madeira)",
              "value": "Atlantic/Madeira"
            },
            {
              "label": "(UTC) Dublin, Lisbon (Europe/Dublin)",
              "value": "Europe/Dublin"
            },
            {
              "label": "(UTC) Dublin, Lisbon (Europe/Lisbon)",
              "value": "Europe/Lisbon"
            },
            {
              "label": "(UTC) Monrovia, Reykjavik (Africa/Abidjan)",
              "value": "Africa/Abidjan"
            },
            {
              "label": "(UTC) Monrovia, Reykjavik (Africa/Accra)",
              "value": "Africa/Accra"
            },
            {
              "label": "(UTC) Monrovia, Reykjavik (Africa/Bamako)",
              "value": "Africa/Bamako"
            },
            {
              "label": "(UTC) Monrovia, Reykjavik (Africa/Banjul)",
              "value": "Africa/Banjul"
            },
            {
              "label": "(UTC) Monrovia, Reykjavik (Africa/Bissau)",
              "value": "Africa/Bissau"
            },
            {
              "label": "(UTC) Monrovia, Reykjavik (Africa/Conakry)",
              "value": "Africa/Conakry"
            },
            {
              "label": "(UTC) Monrovia, Reykjavik (Africa/Dakar)",
              "value": "Africa/Dakar"
            },
            {
              "label": "(UTC) Monrovia, Reykjavik (Africa/Freetown)",
              "value": "Africa/Freetown"
            },
            {
              "label": "(UTC) Monrovia, Reykjavik (Africa/Lome)",
              "value": "Africa/Lome"
            },
            {
              "label": "(UTC) Monrovia, Reykjavik (Africa/Monrovia)",
              "value": "Africa/Monrovia"
            },
            {
              "label": "(UTC) Monrovia, Reykjavik (Africa/Nouakchott)",
              "value": "Africa/Nouakchott"
            },
            {
              "label": "(UTC) Monrovia, Reykjavik (Africa/Ouagadougou)",
              "value": "Africa/Ouagadougou"
            },
            {
              "label": "(UTC) Monrovia, Reykjavik (Africa/Sao_Tome)",
              "value": "Africa/Sao_Tome"
            },
            {
              "label": "(UTC) Monrovia, Reykjavik (Atlantic/Reykjavik)",
              "value": "Atlantic/Reykjavik"
            },
            {
              "label": "(UTC) Monrovia, Reykjavik (Atlantic/St_Helena)",
              "value": "Atlantic/St_Helena"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Arctic/Longyearbyen)",
              "value": "Arctic/Longyearbyen"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Amsterdam)",
              "value": "Europe/Amsterdam"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Andorra)",
              "value": "Europe/Andorra"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Berlin)",
              "value": "Europe/Berlin"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Busingen)",
              "value": "Europe/Busingen"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Gibraltar)",
              "value": "Europe/Gibraltar"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Luxembourg)",
              "value": "Europe/Luxembourg"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Malta)",
              "value": "Europe/Malta"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Monaco)",
              "value": "Europe/Monaco"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Oslo)",
              "value": "Europe/Oslo"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Rome)",
              "value": "Europe/Rome"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/San_Marino)",
              "value": "Europe/San_Marino"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Stockholm)",
              "value": "Europe/Stockholm"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Vaduz)",
              "value": "Europe/Vaduz"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Vatican)",
              "value": "Europe/Vatican"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Vienna)",
              "value": "Europe/Vienna"
            },
            {
              "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Zurich)",
              "value": "Europe/Zurich"
            },
            {
              "label": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Belgrade)",
              "value": "Europe/Belgrade"
            },
            {
              "label": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Bratislava)",
              "value": "Europe/Bratislava"
            },
            {
              "label": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Budapest)",
              "value": "Europe/Budapest"
            },
            {
              "label": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Ljubljana)",
              "value": "Europe/Ljubljana"
            },
            {
              "label": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Podgorica)",
              "value": "Europe/Podgorica"
            },
            {
              "label": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Prague)",
              "value": "Europe/Prague"
            },
            {
              "label": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Tirane)",
              "value": "Europe/Tirane"
            },
            {
              "label": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris (Africa/Ceuta)",
              "value": "Africa/Ceuta"
            },
            {
              "label": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris (Europe/Brussels)",
              "value": "Europe/Brussels"
            },
            {
              "label": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris (Europe/Copenhagen)",
              "value": "Europe/Copenhagen"
            },
            {
              "label": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris (Europe/Madrid)",
              "value": "Europe/Madrid"
            },
            {
              "label": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris (Europe/Paris)",
              "value": "Europe/Paris"
            },
            {
              "label": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb (Europe/Sarajevo)",
              "value": "Europe/Sarajevo"
            },
            {
              "label": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb (Europe/Skopje)",
              "value": "Europe/Skopje"
            },
            {
              "label": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb (Europe/Warsaw)",
              "value": "Europe/Warsaw"
            },
            {
              "label": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb (Europe/Zagreb)",
              "value": "Europe/Zagreb"
            },
            {
              "label": "(UTC+01:00) West Central Africa (Africa/Algiers)",
              "value": "Africa/Algiers"
            },
            {
              "label": "(UTC+01:00) West Central Africa (Africa/Bangui)",
              "value": "Africa/Bangui"
            },
            {
              "label": "(UTC+01:00) West Central Africa (Africa/Brazzaville)",
              "value": "Africa/Brazzaville"
            },
            {
              "label": "(UTC+01:00) West Central Africa (Africa/Douala)",
              "value": "Africa/Douala"
            },
            {
              "label": "(UTC+01:00) West Central Africa (Africa/Kinshasa)",
              "value": "Africa/Kinshasa"
            },
            {
              "label": "(UTC+01:00) West Central Africa (Africa/Lagos)",
              "value": "Africa/Lagos"
            },
            {
              "label": "(UTC+01:00) West Central Africa (Africa/Libreville)",
              "value": "Africa/Libreville"
            },
            {
              "label": "(UTC+01:00) West Central Africa (Africa/Luanda)",
              "value": "Africa/Luanda"
            },
            {
              "label": "(UTC+01:00) West Central Africa (Africa/Malabo)",
              "value": "Africa/Malabo"
            },
            {
              "label": "(UTC+01:00) West Central Africa (Africa/Ndjamena)",
              "value": "Africa/Ndjamena"
            },
            {
              "label": "(UTC+01:00) West Central Africa (Africa/Niamey)",
              "value": "Africa/Niamey"
            },
            {
              "label": "(UTC+01:00) West Central Africa (Africa/Porto-Novo)",
              "value": "Africa/Porto-Novo"
            },
            {
              "label": "(UTC+01:00) West Central Africa (Africa/Tunis)",
              "value": "Africa/Tunis"
            },
            {
              "label": "(UTC+01:00) West Central Africa (Etc/GMT-1)",
              "value": "Etc/GMT-1"
            },
            {
              "label": "(UTC+02:00) Windhoek (Africa/Windhoek)",
              "value": "Africa/Windhoek"
            },
            {
              "label": "(UTC+02:00) Athens, Bucharest (Asia/Nicosia)",
              "value": "Asia/Nicosia"
            },
            {
              "label": "(UTC+02:00) Athens, Bucharest (Europe/Athens)",
              "value": "Europe/Athens"
            },
            {
              "label": "(UTC+02:00) Athens, Bucharest (Europe/Bucharest)",
              "value": "Europe/Bucharest"
            },
            {
              "label": "(UTC+02:00) Athens, Bucharest (Europe/Chisinau)",
              "value": "Europe/Chisinau"
            },
            {
              "label": "(UTC+02:00) Beirut (Asia/Beirut)",
              "value": "Asia/Beirut"
            },
            {
              "label": "(UTC+02:00) Cairo (Africa/Cairo)",
              "value": "Africa/Cairo"
            },
            {
              "label": "(UTC+03:00) Damascus (Asia/Damascus)",
              "value": "Asia/Damascus"
            },
            {
              "label": "(UTC+02:00) E. Europe (Asia/Nicosia)",
              "value": "Asia/Nicosia"
            },
            {
              "label": "(UTC+02:00) E. Europe (Europe/Athens)",
              "value": "Europe/Athens"
            },
            {
              "label": "(UTC+02:00) E. Europe (Europe/Bucharest)",
              "value": "Europe/Bucharest"
            },
            {
              "label": "(UTC+02:00) E. Europe (Europe/Chisinau)",
              "value": "Europe/Chisinau"
            },
            {
              "label": "(UTC+02:00) E. Europe (Europe/Helsinki)",
              "value": "Europe/Helsinki"
            },
            {
              "label": "(UTC+02:00) E. Europe (Europe/Kyiv)",
              "value": "Europe/Kyiv"
            },
            {
              "label": "(UTC+02:00) E. Europe (Europe/Mariehamn)",
              "value": "Europe/Mariehamn"
            },
            {
              "label": "(UTC+02:00) E. Europe (Europe/Nicosia)",
              "value": "Europe/Nicosia"
            },
            {
              "label": "(UTC+02:00) E. Europe (Europe/Riga)",
              "value": "Europe/Riga"
            },
            {
              "label": "(UTC+02:00) E. Europe (Europe/Sofia)",
              "value": "Europe/Sofia"
            },
            {
              "label": "(UTC+02:00) E. Europe (Europe/Tallinn)",
              "value": "Europe/Tallinn"
            },
            {
              "label": "(UTC+02:00) E. Europe (Europe/Uzhgorod)",
              "value": "Europe/Uzhgorod"
            },
            {
              "label": "(UTC+02:00) E. Europe (Europe/Vilnius)",
              "value": "Europe/Vilnius"
            },
            {
              "label": "(UTC+02:00) E. Europe (Europe/Zaporozhye)",
              "value": "Europe/Zaporozhye"
            },
            {
              "label": "(UTC+02:00) Harare, Pretoria (Africa/Blantyre)",
              "value": "Africa/Blantyre"
            },
            {
              "label": "(UTC+02:00) Harare, Pretoria (Africa/Bujumbura)",
              "value": "Africa/Bujumbura"
            },
            {
              "label": "(UTC+02:00) Harare, Pretoria (Africa/Gaborone)",
              "value": "Africa/Gaborone"
            },
            {
              "label": "(UTC+02:00) Harare, Pretoria (Africa/Harare)",
              "value": "Africa/Harare"
            },
            {
              "label": "(UTC+02:00) Harare, Pretoria (Africa/Johannesburg)",
              "value": "Africa/Johannesburg"
            },
            {
              "label": "(UTC+02:00) Harare, Pretoria (Africa/Kigali)",
              "value": "Africa/Kigali"
            },
            {
              "label": "(UTC+02:00) Harare, Pretoria (Africa/Lubumbashi)",
              "value": "Africa/Lubumbashi"
            },
            {
              "label": "(UTC+02:00) Harare, Pretoria (Africa/Lusaka)",
              "value": "Africa/Lusaka"
            },
            {
              "label": "(UTC+02:00) Harare, Pretoria (Africa/Maputo)",
              "value": "Africa/Maputo"
            },
            {
              "label": "(UTC+02:00) Harare, Pretoria (Africa/Maseru)",
              "value": "Africa/Maseru"
            },
            {
              "label": "(UTC+02:00) Harare, Pretoria (Africa/Mbabane)",
              "value": "Africa/Mbabane"
            },
            {
              "label": "(UTC+02:00) Harare, Pretoria (Etc/GMT-2)",
              "value": "Etc/GMT-2"
            },
            {
              "label": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Helsinki)",
              "value": "Europe/Helsinki"
            },
            {
              "label": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Kyiv)",
              "value": "Europe/Kyiv"
            },
            {
              "label": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Mariehamn)",
              "value": "Europe/Mariehamn"
            },
            {
              "label": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Riga)",
              "value": "Europe/Riga"
            },
            {
              "label": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Sofia)",
              "value": "Europe/Sofia"
            },
            {
              "label": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Tallinn)",
              "value": "Europe/Tallinn"
            },
            {
              "label": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Uzhgorod)",
              "value": "Europe/Uzhgorod"
            },
            {
              "label": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Vilnius)",
              "value": "Europe/Vilnius"
            },
            {
              "label": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Zaporozhye)",
              "value": "Europe/Zaporozhye"
            },
            {
              "label": "(UTC+03:00) Istanbul (Europe/Istanbul)",
              "value": "Europe/Istanbul"
            },
            {
              "label": "(UTC+02:00) Jerusalem (Asia/Jerusalem)",
              "value": "Asia/Jerusalem"
            },
            {
              "label": "(UTC+02:00) Tripoli (Africa/Tripoli)",
              "value": "Africa/Tripoli"
            },
            {
              "label": "(UTC+03:00) Amman (Asia/Amman)",
              "value": "Asia/Amman"
            },
            {
              "label": "(UTC+03:00) Baghdad (Asia/Baghdad)",
              "value": "Asia/Baghdad"
            },
            {
              "label": "(UTC+02:00) Kaliningrad (Europe/Kaliningrad)",
              "value": "Europe/Kaliningrad"
            },
            {
              "label": "(UTC+03:00) Kuwait, Riyadh (Asia/Aden)",
              "value": "Asia/Aden"
            },
            {
              "label": "(UTC+03:00) Kuwait, Riyadh (Asia/Bahrain)",
              "value": "Asia/Bahrain"
            },
            {
              "label": "(UTC+03:00) Kuwait, Riyadh (Asia/Kuwait)",
              "value": "Asia/Kuwait"
            },
            {
              "label": "(UTC+03:00) Kuwait, Riyadh (Asia/Qatar)",
              "value": "Asia/Qatar"
            },
            {
              "label": "(UTC+03:00) Kuwait, Riyadh (Asia/Riyadh)",
              "value": "Asia/Riyadh"
            },
            {
              "label": "(UTC+03:00) Nairobi (Africa/Addis_Ababa)",
              "value": "Africa/Addis_Ababa"
            },
            {
              "label": "(UTC+03:00) Nairobi (Africa/Dar_es_Salaam)",
              "value": "Africa/Dar_es_Salaam"
            },
            {
              "label": "(UTC+03:00) Nairobi (Africa/Djibouti)",
              "value": "Africa/Djibouti"
            },
            {
              "label": "(UTC+02:00) Nairobi (Africa/Juba)",
              "value": "Africa/Juba"
            },
            {
              "label": "(UTC+03:00) Nairobi (Africa/Kampala)",
              "value": "Africa/Kampala"
            },
            {
              "label": "(UTC+02:00) Nairobi (Africa/Khartoum)",
              "value": "Africa/Khartoum"
            },
            {
              "label": "(UTC+03:00) Nairobi (Africa/Mogadishu)",
              "value": "Africa/Mogadishu"
            },
            {
              "label": "(UTC+03:00) Nairobi (Africa/Nairobi)",
              "value": "Africa/Nairobi"
            },
            {
              "label": "(UTC+03:00) Nairobi (Antarctica/Syowa)",
              "value": "Antarctica/Syowa"
            },
            {
              "label": "(UTC+03:00) Nairobi (Etc/GMT-3)",
              "value": "Etc/GMT-3"
            },
            {
              "label": "(UTC+03:00) Nairobi (Indian/Antananarivo)",
              "value": "Indian/Antananarivo"
            },
            {
              "label": "(UTC+03:00) Nairobi (Indian/Comoro)",
              "value": "Indian/Comoro"
            },
            {
              "label": "(UTC+03:00) Nairobi (Indian/Mayotte)",
              "value": "Indian/Mayotte"
            },
            {
              "label": "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk (Europe/Kirov)",
              "value": "Europe/Kirov"
            },
            {
              "label": "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk (Europe/Moscow)",
              "value": "Europe/Moscow"
            },
            {
              "label": "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk (Europe/Simferopol)",
              "value": "Europe/Simferopol"
            },
            {
              "label": "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk (Europe/Volgograd)",
              "value": "Europe/Volgograd"
            },
            {
              "label": "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk (Europe/Minsk)",
              "value": "Europe/Minsk"
            },
            {
              "label": "(UTC+04:00) Samara, Ulyanovsk, Saratov (Europe/Astrakhan)",
              "value": "Europe/Astrakhan"
            },
            {
              "label": "(UTC+04:00) Samara, Ulyanovsk, Saratov (Europe/Samara)",
              "value": "Europe/Samara"
            },
            {
              "label": "(UTC+04:00) Samara, Ulyanovsk, Saratov (Europe/Ulyanovsk)",
              "value": "Europe/Ulyanovsk"
            },
            {
              "label": "(UTC+03:30) Tehran (Asia/Tehran)",
              "value": "Asia/Tehran"
            },
            {
              "label": "(UTC+04:00) Abu Dhabi, Muscat (Asia/Dubai)",
              "value": "Asia/Dubai"
            },
            {
              "label": "(UTC+04:00) Abu Dhabi, Muscat (Asia/Muscat)",
              "value": "Asia/Muscat"
            },
            {
              "label": "(UTC+04:00) Abu Dhabi, Muscat (Etc/GMT-4)",
              "value": "Etc/GMT-4"
            },
            {
              "label": "(UTC+04:00) Baku (Asia/Baku)",
              "value": "Asia/Baku"
            },
            {
              "label": "(UTC+04:00) Port Louis (Indian/Mahe)",
              "value": "Indian/Mahe"
            },
            {
              "label": "(UTC+04:00) Port Louis (Indian/Mauritius)",
              "value": "Indian/Mauritius"
            },
            {
              "label": "(UTC+04:00) Port Louis (Indian/Reunion)",
              "value": "Indian/Reunion"
            },
            {
              "label": "(UTC+04:00) Tbilisi (Asia/Tbilisi)",
              "value": "Asia/Tbilisi"
            },
            {
              "label": "(UTC+04:00) Yerevan (Asia/Yerevan)",
              "value": "Asia/Yerevan"
            },
            {
              "label": "(UTC+04:30) Kabul (Asia/Kabul)",
              "value": "Asia/Kabul"
            },
            {
              "label": "(UTC+05:00) Ashgabat, Tashkent (Antarctica/Mawson)",
              "value": "Antarctica/Mawson"
            },
            {
              "label": "(UTC+05:00) Ashgabat, Tashkent (Asia/Aqtau)",
              "value": "Asia/Aqtau"
            },
            {
              "label": "(UTC+05:00) Ashgabat, Tashkent (Asia/Aqtobe)",
              "value": "Asia/Aqtobe"
            },
            {
              "label": "(UTC+05:00) Ashgabat, Tashkent (Asia/Ashgabat)",
              "value": "Asia/Ashgabat"
            },
            {
              "label": "(UTC+05:00) Ashgabat, Tashkent (Asia/Dushanbe)",
              "value": "Asia/Dushanbe"
            },
            {
              "label": "(UTC+05:00) Ashgabat, Tashkent (Asia/Oral)",
              "value": "Asia/Oral"
            },
            {
              "label": "(UTC+05:00) Ashgabat, Tashkent (Asia/Samarkand)",
              "value": "Asia/Samarkand"
            },
            {
              "label": "(UTC+05:00) Ashgabat, Tashkent (Asia/Tashkent)",
              "value": "Asia/Tashkent"
            },
            {
              "label": "(UTC+05:00) Ashgabat, Tashkent (Etc/GMT-5)",
              "value": "Etc/GMT-5"
            },
            {
              "label": "(UTC+05:00) Ashgabat, Tashkent (Indian/Kerguelen)",
              "value": "Indian/Kerguelen"
            },
            {
              "label": "(UTC+05:00) Ashgabat, Tashkent (Indian/Maldives)",
              "value": "Indian/Maldives"
            },
            {
              "label": "(UTC+05:00) Yekaterinburg (Asia/Yekaterinburg)",
              "value": "Asia/Yekaterinburg"
            },
            {
              "label": "(UTC+05:00) Islamabad, Karachi (Asia/Karachi)",
              "value": "Asia/Karachi"
            },
            {
              "label": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi (Asia/Kolkata)",
              "value": "Asia/Kolkata"
            },
            {
              "label": "(UTC+05:30) Sri Jayawardenepura (Asia/Colombo)",
              "value": "Asia/Colombo"
            },
            {
              "label": "(UTC+05:45) Kathmandu (Asia/Kathmandu)",
              "value": "Asia/Kathmandu"
            },
            {
              "label": "(UTC+06:00) Nur-Sultan (Astana) (Antarctica/Vostok)",
              "value": "Antarctica/Vostok"
            },
            {
              "label": "(UTC+06:00) Nur-Sultan (Astana) (Asia/Almaty)",
              "value": "Asia/Almaty"
            },
            {
              "label": "(UTC+06:00) Nur-Sultan (Astana) (Asia/Bishkek)",
              "value": "Asia/Bishkek"
            },
            {
              "label": "(UTC+06:00) Nur-Sultan (Astana) (Asia/Qyzylorda)",
              "value": "Asia/Qyzylorda"
            },
            {
              "label": "(UTC+06:00) Nur-Sultan (Astana) (Asia/Urumqi)",
              "value": "Asia/Urumqi"
            },
            {
              "label": "(UTC+06:00) Nur-Sultan (Astana) (Etc/GMT-6)",
              "value": "Etc/GMT-6"
            },
            {
              "label": "(UTC+06:00) Nur-Sultan (Astana) (Indian/Chagos)",
              "value": "Indian/Chagos"
            },
            {
              "label": "(UTC+06:00) Dhaka (Asia/Dhaka)",
              "value": "Asia/Dhaka"
            },
            {
              "label": "(UTC+06:00) Dhaka (Asia/Thimphu)",
              "value": "Asia/Thimphu"
            },
            {
              "label": "(UTC+06:30) Yangon (Rangoon) (Indian/Cocos)",
              "value": "Indian/Cocos"
            },
            {
              "label": "(UTC+07:00) Bangkok, Hanoi, Jakarta (Antarctica/Davis)",
              "value": "Antarctica/Davis"
            },
            {
              "label": "(UTC+07:00) Bangkok, Hanoi, Jakarta (Asia/Bangkok)",
              "value": "Asia/Bangkok"
            },
            {
              "label": "(UTC+07:00) Bangkok, Hanoi, Jakarta (Asia/Hovd)",
              "value": "Asia/Hovd"
            },
            {
              "label": "(UTC+07:00) Bangkok, Hanoi, Jakarta (Asia/Jakarta)",
              "value": "Asia/Jakarta"
            },
            {
              "label": "(UTC+07:00) Bangkok, Hanoi, Jakarta (Asia/Phnom_Penh)",
              "value": "Asia/Phnom_Penh"
            },
            {
              "label": "(UTC+07:00) Bangkok, Hanoi, Jakarta (Asia/Pontianak)",
              "value": "Asia/Pontianak"
            },
            {
              "label": "(UTC+07:00) Bangkok, Hanoi, Jakarta (Asia/Vientiane)",
              "value": "Asia/Vientiane"
            },
            {
              "label": "(UTC+07:00) Bangkok, Hanoi, Jakarta (Etc/GMT-7)",
              "value": "Etc/GMT-7"
            },
            {
              "label": "(UTC+07:00) Bangkok, Hanoi, Jakarta (Indian/Christmas)",
              "value": "Indian/Christmas"
            },
            {
              "label": "(UTC+07:00) Novosibirsk (Asia/Novokuznetsk)",
              "value": "Asia/Novokuznetsk"
            },
            {
              "label": "(UTC+07:00) Novosibirsk (Asia/Novosibirsk)",
              "value": "Asia/Novosibirsk"
            },
            {
              "label": "(UTC+06:00) Novosibirsk (Asia/Omsk)",
              "value": "Asia/Omsk"
            },
            {
              "label": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi (Asia/Hong_Kong)",
              "value": "Asia/Hong_Kong"
            },
            {
              "label": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi (Asia/Macau)",
              "value": "Asia/Macau"
            },
            {
              "label": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi (Asia/Shanghai)",
              "value": "Asia/Shanghai"
            },
            {
              "label": "(UTC+07:00) Krasnoyarsk (Asia/Krasnoyarsk)",
              "value": "Asia/Krasnoyarsk"
            },
            {
              "label": "(UTC+08:00) Kuala Lumpur, Singapore (Asia/Brunei)",
              "value": "Asia/Brunei"
            },
            {
              "label": "(UTC+08:00) Kuala Lumpur, Singapore (Asia/Kuala_Lumpur)",
              "value": "Asia/Kuala_Lumpur"
            },
            {
              "label": "(UTC+08:00) Kuala Lumpur, Singapore (Asia/Kuching)",
              "value": "Asia/Kuching"
            },
            {
              "label": "(UTC+08:00) Kuala Lumpur, Singapore (Asia/Makassar)",
              "value": "Asia/Makassar"
            },
            {
              "label": "(UTC+08:00) Kuala Lumpur, Singapore (Asia/Manila)",
              "value": "Asia/Manila"
            },
            {
              "label": "(UTC+08:00) Kuala Lumpur, Singapore (Asia/Singapore)",
              "value": "Asia/Singapore"
            },
            {
              "label": "(UTC+08:00) Kuala Lumpur, Singapore (Etc/GMT-8)",
              "value": "Etc/GMT-8"
            },
            {
              "label": "(UTC+08:00) Perth (Antarctica/Casey)",
              "value": "Antarctica/Casey"
            },
            {
              "label": "(UTC+08:00) Perth (Australia/Perth)",
              "value": "Australia/Perth"
            },
            {
              "label": "(UTC+08:00) Taipei (Asia/Taipei)",
              "value": "Asia/Taipei"
            },
            {
              "label": "(UTC+08:00) Ulaanbaatar (Asia/Choibalsan)",
              "value": "Asia/Choibalsan"
            },
            {
              "label": "(UTC+08:00) Ulaanbaatar (Asia/Ulaanbaatar)",
              "value": "Asia/Ulaanbaatar"
            },
            {
              "label": "(UTC+08:00) Irkutsk (Asia/Irkutsk)",
              "value": "Asia/Irkutsk"
            },
            {
              "label": "(UTC+09:00) Osaka, Sapporo, Tokyo (Asia/Dili)",
              "value": "Asia/Dili"
            },
            {
              "label": "(UTC+09:00) Osaka, Sapporo, Tokyo (Asia/Jayapura)",
              "value": "Asia/Jayapura"
            },
            {
              "label": "(UTC+09:00) Osaka, Sapporo, Tokyo (Asia/Tokyo)",
              "value": "Asia/Tokyo"
            },
            {
              "label": "(UTC+09:00) Osaka, Sapporo, Tokyo (Etc/GMT-9)",
              "value": "Etc/GMT-9"
            },
            {
              "label": "(UTC+09:00) Osaka, Sapporo, Tokyo (Pacific/Palau)",
              "value": "Pacific/Palau"
            },
            {
              "label": "(UTC+09:00) Seoul (Asia/Pyongyang)",
              "value": "Asia/Pyongyang"
            },
            {
              "label": "(UTC+09:00) Seoul (Asia/Seoul)",
              "value": "Asia/Seoul"
            },
            {
              "label": "(UTC+10:30) Adelaide (Australia/Adelaide)",
              "value": "Australia/Adelaide"
            },
            {
              "label": "(UTC+10:30) Adelaide (Australia/Broken_Hill)",
              "value": "Australia/Broken_Hill"
            },
            {
              "label": "(UTC+09:30) Darwin (Australia/Darwin)",
              "value": "Australia/Darwin"
            },
            {
              "label": "(UTC+10:00) Brisbane (Australia/Brisbane)",
              "value": "Australia/Brisbane"
            },
            {
              "label": "(UTC+10:00) Brisbane (Australia/Lindeman)",
              "value": "Australia/Lindeman"
            },
            {
              "label": "(UTC+11:00) Canberra, Melbourne, Sydney (Australia/Melbourne)",
              "value": "Australia/Melbourne"
            },
            {
              "label": "(UTC+11:00) Canberra, Melbourne, Sydney (Australia/Sydney)",
              "value": "Australia/Sydney"
            },
            {
              "label": "(UTC+10:00) Guam, Port Moresby (Antarctica/DumontDUrville)",
              "value": "Antarctica/DumontDUrville"
            },
            {
              "label": "(UTC+10:00) Guam, Port Moresby (Etc/GMT-10)",
              "value": "Etc/GMT-10"
            },
            {
              "label": "(UTC+10:00) Guam, Port Moresby (Pacific/Guam)",
              "value": "Pacific/Guam"
            },
            {
              "label": "(UTC+10:00) Guam, Port Moresby (Pacific/Port_Moresby)",
              "value": "Pacific/Port_Moresby"
            },
            {
              "label": "(UTC+10:00) Guam, Port Moresby (Pacific/Saipan)",
              "value": "Pacific/Saipan"
            },
            {
              "label": "(UTC+11:00) Hobart (Australia/Currie)",
              "value": "Australia/Currie"
            },
            {
              "label": "(UTC+11:00) Hobart (Australia/Hobart)",
              "value": "Australia/Hobart"
            },
            {
              "label": "(UTC+09:00) Yakutsk (Asia/Chita)",
              "value": "Asia/Chita"
            },
            {
              "label": "(UTC+09:00) Yakutsk (Asia/Khandyga)",
              "value": "Asia/Khandyga"
            },
            {
              "label": "(UTC+09:00) Yakutsk (Asia/Yakutsk)",
              "value": "Asia/Yakutsk"
            },
            {
              "label": "(UTC+11:00) Solomon Is., New Caledonia (Antarctica/Macquarie)",
              "value": "Antarctica/Macquarie"
            },
            {
              "label": "(UTC+11:00) Solomon Is., New Caledonia (Etc/GMT-11)",
              "value": "Etc/GMT-11"
            },
            {
              "label": "(UTC+11:00) Solomon Is., New Caledonia (Pacific/Efate)",
              "value": "Pacific/Efate"
            },
            {
              "label": "(UTC+11:00) Solomon Is., New Caledonia (Pacific/Guadalcanal)",
              "value": "Pacific/Guadalcanal"
            },
            {
              "label": "(UTC+11:00) Solomon Is., New Caledonia (Pacific/Kosrae)",
              "value": "Pacific/Kosrae"
            },
            {
              "label": "(UTC+11:00) Solomon Is., New Caledonia (Pacific/Noumea)",
              "value": "Pacific/Noumea"
            },
            {
              "label": "(UTC+11:00) Vladivostok (Asia/Sakhalin)",
              "value": "Asia/Sakhalin"
            },
            {
              "label": "(UTC+10:00) Vladivostok (Asia/Ust-Nera)",
              "value": "Asia/Ust-Nera"
            },
            {
              "label": "(UTC+10:00) Vladivostok (Asia/Vladivostok)",
              "value": "Asia/Vladivostok"
            },
            {
              "label": "(UTC+13:00) Auckland, Wellington (Antarctica/McMurdo)",
              "value": "Antarctica/McMurdo"
            },
            {
              "label": "(UTC+13:00) Auckland, Wellington (Pacific/Auckland)",
              "value": "Pacific/Auckland"
            },
            {
              "label": "(UTC+12:00) Coordinated Universal Time+12 (Etc/GMT-12)",
              "value": "Etc/GMT-12"
            },
            {
              "label": "(UTC+12:00) Coordinated Universal Time+12 (Pacific/Funafuti)",
              "value": "Pacific/Funafuti"
            },
            {
              "label": "(UTC+12:00) Coordinated Universal Time+12 (Pacific/Kwajalein)",
              "value": "Pacific/Kwajalein"
            },
            {
              "label": "(UTC+12:00) Coordinated Universal Time+12 (Pacific/Majuro)",
              "value": "Pacific/Majuro"
            },
            {
              "label": "(UTC+12:00) Coordinated Universal Time+12 (Pacific/Nauru)",
              "value": "Pacific/Nauru"
            },
            {
              "label": "(UTC+12:00) Coordinated Universal Time+12 (Pacific/Tarawa)",
              "value": "Pacific/Tarawa"
            },
            {
              "label": "(UTC+12:00) Coordinated Universal Time+12 (Pacific/Wake)",
              "value": "Pacific/Wake"
            },
            {
              "label": "(UTC+12:00) Coordinated Universal Time+12 (Pacific/Wallis)",
              "value": "Pacific/Wallis"
            },
            {
              "label": "(UTC+12:00) Fiji (Pacific/Fiji)",
              "value": "Pacific/Fiji"
            },
            {
              "label": "(UTC+12:00) Magadan (Asia/Anadyr)",
              "value": "Asia/Anadyr"
            },
            {
              "label": "(UTC+12:00) Magadan (Asia/Kamchatka)",
              "value": "Asia/Kamchatka"
            },
            {
              "label": "(UTC+11:00) Magadan (Asia/Magadan)",
              "value": "Asia/Magadan"
            },
            {
              "label": "(UTC+11:00) Magadan (Asia/Srednekolymsk)",
              "value": "Asia/Srednekolymsk"
            },
            {
              "label": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old (Asia/Kamchatka)",
              "value": "Asia/Kamchatka"
            },
            {
              "label": "(UTC+13:00) Nuku'alofa (Etc/GMT-13)",
              "value": "Etc/GMT-13"
            },
            {
              "label": "(UTC+13:00) Nuku'alofa (Pacific/Enderbury)",
              "value": "Pacific/Enderbury"
            },
            {
              "label": "(UTC+13:00) Nuku'alofa (Pacific/Fakaofo)",
              "value": "Pacific/Fakaofo"
            },
            {
              "label": "(UTC+13:00) Nuku'alofa (Pacific/Tongatapu)",
              "value": "Pacific/Tongatapu"
            },
            {
              "label": "(UTC+13:00) Samoa (Pacific/Apia)",
              "value": "Pacific/Apia"
            }
          ],
        currentValue:null
      }
    }
  
    componentDidMount(){
        console.log(JSON.stringify(this.state.select_data));
        if(this.props.value != null){
            for(let i=0; i<this.state.select_data.length; i++){
                if(this.state.select_data[i].value == this.props.value){
                    this.setState({
                        currentValue:this.state.select_data[i]
                    })
                }
            }
        }
    }

    Change = (res:any) => {
        if(this.props.onChange != null){
            this.props.onChange(res);
            this.setState({
                currentValue:res
            })
        }
    }

    
  
  
    render() {
        return(
            <Select value={this.state.currentValue} onChange={(res)=>{this.Change(res)}}  options={this.state.select_data} />
        )
    }
}

